import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import {
    Container,
} from './styles';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AlertNotify = ({ type, time, message, state }) => {

    const [openSucces, setSucces] = React.useState(state);
    const handleClose = () => {
        setSucces(false);
    }
    return (
        <Container>
            <Snackbar open={openSucces} autoHideDuration={time} onClose={handleClose}>
                <Alert onClose={handleClose} severity={type}>
                    {message}
                </Alert>
            </Snackbar>
        </Container>
    );
}

export default AlertNotify;