import ContentHeader from '../../Components/ContentHeader';
import WelcomeBanner from '../../Components/WelcomeBanner';
import NumbersTable from '../../Components/NumbersTable';
import ModalQRCode from '../../Components/ModalQRCode';
import { ButtonBlue } from '../../../Styles/buttons';
import { useTranslation } from "react-i18next";
import { Container, Content } from './styles';
import React, { useEffect } from 'react';
import { fetchNumbers } from '../../../Services/numberFunctions';
import { Helmet } from 'react-helmet';


const MyNumbers = () => {
  const { t } = useTranslation();

  const [numbers, setNumbers] = React.useState([]);

  const updateNumbersList = async () => {
    const numbers = await fetchNumbers()
    setNumbers(numbers)
  }

  useEffect(() => updateNumbersList(), [])

  return (
    <Container>
      <Helmet>
        <title> Numbers | Builderall</title>
      </Helmet>
      <ContentHeader title={t("words.my_numbers_reg")} subTitle="">
        <ModalQRCode title="MyNumbers" Parameter="New" onClose={() => updateNumbersList()} button={<ButtonBlue > {t("words.new_number")} </ButtonBlue>} />
      </ContentHeader>
      <WelcomeBanner />
      <Content>
        <NumbersTable numbers={numbers} updateNumbersList={updateNumbersList} />
      </Content>
    </Container>
  );
}

export default MyNumbers;