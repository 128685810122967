import styled from "styled-components";
import { makeStyles } from '@material-ui/core/styles';

export const Container = styled.div`
`;

export const ChatList = styled.div`
    display: flex;
    align-items: center;
    height: 75px;
    border-bottom: solid 1px #EAF1FC ;
    &:hover{
        background-color: #f5f5f5 ;
    }
`;
export const ChatName = styled.div`
    display: flex;
    width: 70%;
    
    >p{
        text-align: left;
        font: normal normal 600 15px/14px Mulish;
        letter-spacing: 0px;
        color: #434343;
        opacity: 1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin: 0;

    }
`;
export const UseStyle = makeStyles((theme) => ({
    root: {
        backgroundColor: '#F2F7FD ',
        '&:hover': {
            backgroundColor: '#EBEBEB ',
        },
    },
    modal: {
        backgroundColor: "#F3F7FF ",
        boxShadow: "0px 14px 32px #00000040",
        borderRadius: "10px",
        opacity: "1",
        maxWidth: "649px",
        alignContent: "center",
        alignItems: "center",

    },
    textArea: {
        width: '100%',
        height: '100%',
        border: '1px solid #D0DAED',
        outline: 0,
        backgroundColor: '#FFFFFF ',
        fontSize: '14px',
        color: '#4a4a4a',
        padding: '15px',
        overflow: "scroll",
        borderRadius: '3px',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },

}));
export const ChatMessage = styled.div`
    font-size: 14px;
    color: #999;
    display: flex;
    width: 70%;
    white-space: nowrap;
    >p{
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0;

    }
`;
export const Btn = styled.div`
    width: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all ease 0.2s;
    cursor: pointer;
    color: #0072FF;
    &:hover{
        color: #0072FF;
    }
`;