import { createContext, useContext, useEffect } from 'react'
import builderallLogo from '../Assets/icon/wlm.svg'
import builderallFavicon from '../Assets/favicon.ico'
import builderallBusinessLogo from '../Assets/CommunIcons/backoffice  Inactive.svg'
import avizapLogo from '../Assets/avizap/logo.svg'
import avizapFavicon from '../Assets/avizap/favicon.svg'
import avizapBusinessLogo from '../Assets/avizap/business-logo.svg'


const apps = {
    'wppg.builderall.com': {
        name: 'WhatsApp Launch Manager',
        logo: builderallLogo,
        favicon: builderallFavicon,
        business: {
            name: 'Builderall',
            logo: builderallBusinessLogo,
            url: 'https://office.builderall.com/us/office'
        },
        support: 'https://ba-support.builderall.com/client-chat',
    },
    'chat.avizap.com': {
        name: 'Central de Atendimento',
        logo: avizapLogo,
        favicon: avizapFavicon,
        business: {
            name: 'Avizap',
            logo: avizapBusinessLogo,
            url: 'https://app.avizap.com'
        },
    },
    'chat.funnelcontrol.online': {
        name: 'Central de Atendimento',
        logo: avizapLogo,
        favicon: avizapFavicon,
        business: {
            name: 'Funnel Control',
            logo: avizapBusinessLogo,
            url: 'https://app.funnelcontrol.online'
        }
    },
}

const appDefault = apps['wppg.builderall.com']

const appContext = createContext(appDefault)

export const AppProvider = ({ children }) => {
    const { hostname } = window.location
    const app = apps[hostname] || appDefault

    useEffect(() => {
        document.title = `${app.name} | ${app.business.name}`

        const favicon = document.createElement('link')
        favicon.setAttribute('rel', 'icon')
        favicon.setAttribute('href', app.favicon)
        document.querySelector('head').appendChild(favicon)
    }, [app])

    return (
        <appContext.Provider value={app}>
            {children}
        </appContext.Provider>
    )
}

const useApp = () => useContext(appContext)

export default useApp