import styled from "styled-components";
import { Modal } from 'reactstrap';

export const CustomModal = styled(Modal)`
    .modal-content {
        width: 600px;
        height: 600px;
        background-color: #FFFFFF;
        border: 0px;
    }

    .modal-footer {
        border-top: 0px;
    }

    .modal-header{
        border-bottom: 0;
    }

    .modal-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        font-family: 'Mulish';
        font-weight: bold;

        > img {
            width: 70%;
            margin: 0 auto;
        }
        .TextDivs {
            color: #4E4E4E;
            margin-top: 45px;
            
            > h4 {
                font-size: 20px;
                font-weight: bold;
                text-transform: uppercase;
            }

            > h5 {
                font-weight: bold;
                font-size: 18px;
            }
        }
    }
`;

export const ButtonsDiv = styled.div`
    width: 100%;
    display: flex;
    justify-content: right;
`;