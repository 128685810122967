import React from 'react';
import isMobile from '../../../Services/isMobile';
import {
    Container,
    LeftDiv,
    RightDiv
} from './styles';


interface IBackgroudProps {
    text?: string;
    children?: React.ReactNode;
    title?: string;
    img?: string;
    icon?: string;
}

const Backgroud: React.FC<IBackgroudProps> = ({ text, title, children, img, icon }) => {
    return (
        <Container>
            {!isMobile() || window.innerWidth > 700 ?
                <LeftDiv>
                    <img src={img} alt="Imagem de fundo"></img>
                </LeftDiv>
                :
                <></>
            }
            <RightDiv>
                <div className='titleDiv'>
                    <img src={icon} alt="icone" />
                    <h3>{title}</h3>
                </div>
                <p>{text}</p>
                {children}
            </RightDiv>
        </Container>
    );
}

export default Backgroud;