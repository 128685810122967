import React from 'react';
import { Button } from '@material-ui/core';
import { BtnBlue2 } from '../../../Styles/buttons';
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import axios_base from '../../../axios_base';
import isMobile from '../../../Services/isMobile';
import InviteError from '../../Components/InviteError';
import Browser from '../../../Assets/CommunIcons/Browser.svg';
import Desktop from '../../../Assets/CommunIcons/Desktop.svg';
import * as DataFormat from '../../../Services/formartText';

import {
  Container,
  Image,
  NameGroup,
  SectionOne,
  UseStyles,
  JoinContainer,
  JoinQuestion,
  Box,
  Boxes,
  ImgDiv,
  Or
} from './styles';


const Invite = () => {
  let { hash } = useParams();
  const { t } = useTranslation();
  const classes = UseStyles();

  const BtnBlue = BtnBlue2();

  const [campaign, setCampaign] = React.useState(null);
  const [invite, setInvite] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState(null);

  const getCampaign = async () => {
    const { data: campaign } = await axios_base.get(`public/campaign/${hash}`)
    setCampaign({
      ...campaign,
      group_image_url: `${process.env.REACT_APP_LINK_API}/storage/${campaign.group_image}`
    })
  }

  const getInvite = async () => {
    const { data: { invite } } = await axios_base.get(`public/campaign/${hash}/invite`)
    setInvite(invite)
  }

  React.useEffect(() => (async () => {
    if (!hash) { return }

    setIsLoading(true)
    setError(null)

    try {
      await Promise.all([
        getCampaign(),
        getInvite()
      ])
    } catch (err) {
      setError(err)
    } finally {
      setIsLoading(false)
    }
  })(), [hash]);

  React.useEffect(() => {
    try {
      if (campaign?.facebook_pixel_script) {
        eval(campaign.facebook_pixel_script)
      }
    } catch (err) {
      console.error('FacebookPixelScript Error', err)
    }
  }, [campaign])

  React.useEffect(() => {
    if (invite && isMobile()) {
      window.open(`https://chat.whatsapp.com/${invite}`, '_self')
    }
  }, [invite])

  return (
    <Container>
      <SectionOne>
        <div id="sectionOne" style={{}}>
          <div style={{ display: 'flex', alignItems: 'center', textAlign: 'center', paddingBottom: '15px' }}>
            <div className="row">
              <div className="col">
                <Image>
                  <div style={{ width: '100%', height: '150px', cursor: 'pointer', backgroundColor: '#b8b8b8', backgroundSize: 'cover', backgroundImage: `url(${campaign?.group_image_url})`, textAlign: 'center', paddingTop: '10%', borderRadius: '100%', boxShadow: '0px 3px 6px #00000029' }}>

                  </div>
                  <div id="statusImage"></div>
                </Image>
              </div>
            </div>
          </div>
        </div>
      </SectionOne>
      <div className="container">
        <div className="row">
          <div className="col">
            <NameGroup>
              <h5>
                {campaign?.group_name}
              </h5>
              <div style={{ maxWidth: '50vw' }}>
                <p dangerouslySetInnerHTML={{ __html: DataFormat.formatText(campaign?.group_description) }}>
                </p>
              </div>
            </NameGroup>
          </div>
        </div>
      </div>

      {error && <h6>{{
        'record not found': <InviteError text={t("words.unavailable_group")} image="not_found" />,
        'member limit reached': <InviteError text={t("words.member_limit_reached")} image="member_limit" />,
        'archived campaign': <InviteError text={t("words.campaign_archived_error")} image="archived" />
      }[error?.response?.data?.message] || <InviteError text={t("words.something_wrong")} image="something_wrong" />}</h6>}

      {isLoading && <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>}

      {!isLoading && !error &&
        <JoinContainer>
          <JoinQuestion>{t("words.join_question")}</JoinQuestion>
          <Boxes>
            <Box>
              <ImgDiv>
                <img src={Browser} alt="web" />
              </ImgDiv>
              <div>
                <a href={`https://web.whatsapp.com/accept?code=${invite}`} style={{ textDecoration: 'none' }}><Button classes={{ root: BtnBlue.root, label: BtnBlue.label, }} style={{ margin: '5px', width: '100px' }}> {t("words.web")}</Button></a>
              </div>
            </Box>
            <Or>{t("words.or")}</Or>
            <Box>
              <ImgDiv>
                <img src={Desktop} alt="desktop" />
              </ImgDiv>
              <div>
                <a href={`https://chat.whatsapp.com/${invite}`} style={{ textDecoration: 'none' }}><Button classes={{ root: BtnBlue.root, label: BtnBlue.label, }} style={{ margin: '5px', width: '100px' }}> {t("words.desk")}</Button> </a>
              </div>
            </Box>
          </Boxes>
        </JoinContainer>}

      <Divider className={classes.divider} />
      <p>{t("words.have_whatsapp")}</p>
      <a href="https://www.whatsapp.com/download" target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>{t("words.download")}</a>
    </Container>
  )
}


export default Invite;