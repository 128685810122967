import { Container, CustomModal, NoOperators } from './styles.js';
import { ModalFooter, ModalBody, ModalHeader, } from 'reactstrap';
import SupportIcon from '../../../Assets/admin_img/support.svg';
import { cancelBtn2 } from '../../../Styles/buttons';
import { useTranslation } from "react-i18next";
import axios_base from '../../../axios_base';
import { Button } from '@material-ui/core';
import { Table } from 'reactstrap';
import moment from 'moment';
import React from 'react';
import DateFormat from '../../../Common/Components/DateFormat/index.jsx';
import dayjs from 'dayjs';

const OnlineAttendantsModal = ({ toggle, isOpen, number }) => {

    const [operatorList, setOperatorList] = React.useState([]);

    const deadline = moment().add(-1, 'minute')
    const { t } = useTranslation();
    const CancelBtn = cancelBtn2();

    React.useEffect(() => {
        if (isOpen) {
            const getAttendants = async () => {
                try {
                    const res = await axios_base.get(`/number/${number?.id}/operator`)
                    setOperatorList(res.data)

                } catch (error) {

                }
            }
            getAttendants()
        }
    }, [isOpen, number])

    return (
        <Container>
            <CustomModal isOpen={isOpen} toggle={toggle} size='lg'>
                <ModalHeader>
                    {t("words.online_operators").toUpperCase()}
                </ModalHeader>
                <ModalBody>
                    {operatorList.length ?
                        <Table striped bordered hover size="sm">
                            <thead className='text-center'>
                                <tr>
                                    <th>{t("words.name")}</th>
                                    <th>{t("words.date")}</th>
                                    <th>{t("words.hour")}</th>
                                </tr>
                            </thead>
                            {operatorList.map(operator => (
                                <tbody key={operator.id} className='text-center'>
                                    <tr>
                                        <td>{operator.name}</td>
                                        <td>{operator.logged_at ? <DateFormat date={operator.logged_at} /> : t("words.never_logged")}</td>
                                        <td>{operator.logged_at ? (dayjs(operator.logged_at) >= deadline ? "Online" : dayjs(operator.logged_at).fromNow()) : t("words.never_logged")}</td>
                                    </tr>
                                </tbody>
                            ))}
                        </Table>
                        :
                        <NoOperators>
                            <span className='alert'><img src={SupportIcon} alt="suporte" />{t("words.no_operators_registered")}</span>
                        </NoOperators>
                    }
                </ModalBody>
                <ModalFooter>
                    <Button classes={{ root: CancelBtn.root, label: CancelBtn.label, }} onClick={toggle} className="cancelButton" style={{ marginRight: '10px' }}>{t("words.close")}</Button>
                </ModalFooter>
            </CustomModal>


        </Container>
    )
}

export default OnlineAttendantsModal;