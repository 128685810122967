import React from "react";


export const CommunContext = React.createContext({});

export const CommunProvider = (props) => {

    const [informatios, setInformations] = React.useState({
        phoneName: "",
        phoneNumber: null,
        phoneID: null,
    })

    const [isAsideOpen, setIsAsideOpen] = React.useState(false)
    const [isMobileState, setIsMobileState] = React.useState(false);
    const [screenWidth, setScreenWidth] = React.useState(0);
    const [screenHeight, setScreenHeight] = React.useState(0);
    const [dateFormat, setDateFormat] = React.useState("MM/DD/YYYY")


    return (
        <CommunContext.Provider value={{ informatios, setInformations, isAsideOpen, setIsAsideOpen, isMobileState, setIsMobileState, screenWidth, setScreenWidth, screenHeight, setScreenHeight, dateFormat, setDateFormat }}>
            {props.children}
        </CommunContext.Provider>
    )
}

export const useCommun = () => React.useContext(CommunContext);