import CampaignScreen from '../Launch/Pages/CampaignScreen';
import Not_Authorized from '../Common/Pages/Not_Authorized'
import AdminDashboard from '../Admin/Pages/AdminDashboard';
import Authenticate from '../Common/Pages/Authenticate';
import Layout from '../Common/Components/Layouts';
import Dashboard from '../Common/Pages/Dashboard';
import MyNumbers from '../Common/Pages/MyNumbers';
import Login from '../Common/Pages/Login';
import NoMatch from '../Common/Pages/404';
import Invite from '../Launch/Pages/Invite';
import Groups from '../Launch/Pages/Groups';
import Privateroute from '../Hooks/auth';
import LoginChat from '../Chat/Pages/LoginChat';
import { Route, Switch } from 'react-router-dom';
import AttendantLogin from '../Chat/Pages/AttendantLogin';

const AppRoutes = () => (
    <Layout>
        <Switch  >
            {/* Launch */}
            <Route exact path="/" component={LoginChat} />
            {/*  <Route exact path="/attendant/login/:access_code" component={AttendantLogin} /> */}
            <Route exact path="/authenticate/:token/" component={Login} />
            <Privateroute exact path="/authenticate" component={Authenticate} />
            <Privateroute exact path="/campaigns/dashboard/:id?" component={Dashboard} />
            <Privateroute exact path="/campaign/:id/detail" component={CampaignScreen} />
            <Privateroute exact path="/campaign/:id/groups" component={Groups} />
            {/* Attendance */}
            <Privateroute exact path="/attendance/dashboard/:id" component={AdminDashboard} />
            {/* General */}
            <Privateroute exact path="/numbers" component={MyNumbers} />
            <Route exact path="/unauthorized" component={Not_Authorized} />
            <Route exact path="/campaign/:hash/invite" component={Invite} />
            <Route component={NoMatch} />
        </Switch>
    </Layout>



);

export default AppRoutes;