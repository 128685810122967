import React from 'react';
import { ThemeProvider } from 'styled-components'
import GlobalStyles from './Styles/GlobalStyles';
/* import dark from './Styles/themes/dark'; */
import light from './Styles/themes/light';
import Routes from './Routes';
import { initializeInstallPrompt } from './installPrompt';
import axios from 'axios';
import i18n from './i18n';

const App = () => {
    const getLangOffice = async () => {
        try {
            const { data: res } = await axios({ method: 'get', url: `${process.env.REACT_APP_OFFICE_API}/v1/user`, headers: { 'Authorization': `Bearer ${localStorage.getItem('primaryToken')}` }, });
            console.log(res)
            localStorage.setItem('officeLang', res?.data?.lang);
            setLang(res?.data?.lang)
        } catch (error) {
            setLang("en");
        }

    }

    const setLang = (lang) => {
        if (lang === "en_US" || lang === "en") {
            i18n.changeLanguage("en")
        }
        if (lang === "es_ES" || lang === "es") {
            i18n.changeLanguage("es")
        } if (lang === "pt_BR" || lang === "pt") {
            i18n.changeLanguage("pt")
        } if (lang === "de" || lang === "de_DE") {
            i18n.changeLanguage("de")
        } if (lang === "fr" || lang === "fr_FR") {
            i18n.changeLanguage("fr")
        } if (lang === "it" || lang === "it_IT") {
            i18n.changeLanguage("it")
        } if (lang === "ru" || lang === "ru") {
            i18n.changeLanguage("ru")
        }
    }

    React.useEffect(() => {
        getLangOffice();
    }, [])

    initializeInstallPrompt()
    return (
        <ThemeProvider theme={light}>
            <GlobalStyles />
            {/* <builderall-appshell id="appshell" token="" tool="whatsapp-launch-manager"></builderall-appshell> */}
            <Routes />
        </ThemeProvider>
    );
}

export default App;