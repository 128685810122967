import React from 'react';
import ChatContent from '../../Components/ChatContent';
import ChatAside from '../../Components/ChatAside';
import ChatAsideMobile from '../../Components/Mobile/ChatAsideMobile';
import { useHistory, useLocation } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import isMobile from '../../../Services/isMobile';
import { useChat } from '../../../Providers/chat';
import { Helmet } from 'react-helmet';

import {
    Container,
} from './styles';


const Conversations = () => {

    const history = useHistory();
    const location = useLocation();
    const { chatOpen, setChatOpen } = useChat();
    var windowWidth = window.innerWidth;

    const [isMobileState, setIsMobile] = React.useState(false);

    function recarregarPagina() {
        history?.push('/attendant/chat');
    }

    var TimeOut;
    window.onresize = function () {
        clearTimeout(TimeOut);
        TimeOut = setTimeout(recarregarPagina, 10);
    };
    React.useEffect(() => {
        localStorage.setItem('chat', "null");
        setChatOpen(false);
        if (localStorage.getItem('sectorActive')) {

        } else {
            localStorage.setItem('sectorActive', "null");
        }
    }, []);

    React.useEffect(() => {
        const mobile = isMobile();
        setIsMobile(mobile);
    }, [location])

    return (
        <Container>
            <Helmet>
                <title> Chat | Builderall</title>
            </Helmet>
            <div>
                {windowWidth > 960 && isMobileState === false ? <Grid container spacing={3} >
                    <Grid item xs={4} >
                        <ChatAside />
                    </Grid>
                    <Grid item xs={8} >
                        <ChatContent />
                    </Grid>
                </Grid> : chatOpen !== false ? <Grid item xs={12} > <ChatContent /> </Grid> : <ChatAsideMobile />}
            </div>

        </Container>
    )
}

export default Conversations;