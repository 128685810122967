import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

export const Container = styled.div`
    grid-area: AS;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 3px #26242424;
    opacity: 1;
    transition: all ease 0.1s;
    height: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 3px rgb(38 36 36 / 14%);
`;
export const UseStyle = makeStyles((theme) => ({
    iten: {
        color: '#A5B9D5',
        '&:hover': {
            color: '#0F7BFF',
        },
    },
}));

export const Iten = styled.div`
     display: flex;
     
    flex-direction: row;
    align-items: center;
    height: 60px;
    padding: 5%;
    color: #A5B9D5;
    font: normal normal 600 1rem/2rem Mulish;
    letter-spacing: 0px;
    cursor: pointer;
    &:hover{
        background: #F4F7FC;
        color: #0F7BFF;
    }
    >div {
        padding-left: 5%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        transition: all ease 0.1s;
    }
`;

export const Line = styled.div`

`;