import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import finishedConversantionGray from '../../../Assets/IconesChat/FinishedConversationsGray.svg';
import finishedConversantion from '../../../Assets/IconesChat/FinishedConversations.svg';
import { FinishAttButton, BtnBlue2, cancelBtn2 } from '../../../Styles/buttons';
import axios_base_chat from '../../../axios_base_chat';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import MuiAlert from '@material-ui/lab/Alert';
import { useChat } from '../../../Providers/chat';
import { useTranslation } from 'react-i18next';

import {
    Container,
    UseStyle,
    Bottom,
    Middle,
    Top,
} from "./styles";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const FinisheAttendance = ({ id, name, device }) => {

    const buttonBlue = FinishAttButton();
    const history = useHistory();
    const classes = UseStyle();
    const blueB = BtnBlue2();
    const cancelBtn = cancelBtn2();
    const { chat, setChatOpen } = useChat();
    const { t } = useTranslation();

    const [message, setMessage] = React.useState();

    const [attendanceFinished, setFinished] = React.useState(false);
    const [modal, setModal] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [isFinishing, setIsFinishing] = React.useState(false);

    const handleClose = () => {
        setFinished(false);
        setError(false)
    }

    const handClick = () => {
        setModal(!modal);
        setMessage(null)
    };

    const finisheAttendance = (message) => {
        setIsFinishing(true);
        var data = {
            "message": message != null ? message : t("words.finished_msg"),
        }
        try {
            axios_base_chat.patch(`chat/department/${chat.sectorID}/attendance/${id}/finish`, data)
                .then(res => {
                    localStorage.setItem("updated", "true");
                    localStorage.setItem('chat', "null");
                    setFinished(true);
                    handClick();
                    setIsFinishing(false);
                    setChatOpen(false);
                    history.push('/attendant/chat');
                })
                .catch(err => {
                    setError(true);
                })
        } catch (error) {

        }
    }


    return (
        <Container>

            {localStorage.getItem("operator") !== localStorage.getItem("operatorId") ?
                device === "mobile" ? <img src={finishedConversantionGray} alt=" " style={{ marginRight: '10px' }} />:
                    <Button classes={{ root: buttonBlue.root, label: buttonBlue.label, }} style={{ color: '#A5B9D5' }}>
                        <img src={finishedConversantionGray} alt=" " style={{ marginRight: '10px' }} /> {t("words.finish")}
                    </Button> :
                device === "mobile" ? <img src={finishedConversantion} alt=" " style={{ marginRight: '10px' }} onClick={handClick} /> :
                    <Button classes={{ root: buttonBlue.root, label: buttonBlue.label, }} onClick={handClick}>
                        <img src={finishedConversantion} alt=" " style={{ marginRight: '10px' }} /> {t("words.finish")}
                    </Button>}


            <Modal isOpen={modal} toggle={handClick} className={classes.modal}>
                <ModalBody style={{ padding: '25px 30px' }}>
                    <Top>
                        <h3>{t("words.finish_att")}</h3>
                    </Top>
                    <Middle>
                        <div>
                            <p>{t("words.finished_att_desc")}</p>
                        </div>
                        <label for="message" >{t("words.message")}</label>
                        <div>
                            <textarea id="message" onChange={e => setMessage(e.target.value)} value={message != null ? message : t("words.finished_msg")} placeholder={t("words.finish_att_placeholder")} className={classes.textArea} rows={6}></textarea>
                        </div>
                    </Middle>
                    <Bottom>
                        <div>
                            <Button classes={{ root: cancelBtn.root, label: cancelBtn.label, }} onClick={handClick}>
                                {t("words.cancel")}
                            </Button>
                        </div>
                        <div>
                            {isFinishing === true ?
                                <Button classes={{ root: blueB.root, label: blueB.label, }} disabled>
                                    {t("words.finish_att")}
                                </Button> :
                                <Button classes={{ root: blueB.root, label: blueB.label, }} onClick={() => finisheAttendance(message)}>
                                    {t("words.finish_att")}
                                </Button>}

                        </div>
                    </Bottom>
                </ModalBody>
                <Snackbar open={attendanceFinished} autoHideDuration={3000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success">
                        {t("words.finished_alert")}
                    </Alert>
                </Snackbar>
                <Snackbar open={error} autoHideDuration={3000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="error">
                        {t("words.something_wrong")}
                    </Alert>
                </Snackbar>
            </Modal>

        </Container>
    )

}

export default FinisheAttendance;