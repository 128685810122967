import React from 'react'
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import offlineIcon from '../../../Assets/icon/disconnected from launch.svg'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import onlineIcon from '../../../Assets/icon/connected by qrcode.svg'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Timeline from '@material-ui/lab/Timeline';
import { useTranslation } from 'react-i18next';
import { Container, LogText } from './styles'
import DateFormat from '../DateFormat';

const LogTimeline = ({ num }) => {
    var language = navigator.language.substring(0, 2);
    const { t } = useTranslation();

    var dateFormat = null;
    if (language === 'pt') {
        dateFormat = 'D/MM/YYYY, LTS ';
    } else {
        dateFormat = 'MMMM DD YYYY, h:mm:ss a';
    }

    return (
        <Container>
            {num.logs?.map((log) => (
                <Timeline align="alternate">
                    <TimelineItem>
                        <TimelineOppositeContent>
                            <LogText>
                                <DateFormat date3L={false} date={log.created_at} />
                            </LogText>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineDot>
                                {(log.type === "connected" || log.type === "reconnected") &&
                                    <img src={onlineIcon} alt="online" />
                                }
                                {log.type === "disconnected" &&
                                    <img src={offlineIcon} alt="offline" />
                                }
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                            <LogText>
                                {log.type === "connected" &&
                                    t("words.conected")
                                }
                                {log.type === "reconnected" &&
                                    t("words.reconnected")
                                }
                                {log.type === "disconnected" &&
                                    t("words.disconnected")
                                }
                            </LogText>
                        </TimelineContent>
                    </TimelineItem>
                </Timeline>
            ))}
            <Timeline align="alternate">
                <TimelineItem>
                    <TimelineOppositeContent>
                        <LogText>
                            <DateFormat date3L={false} date={num.created_at} />
                        </LogText>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot>
                            <PlayCircleOutlineIcon />
                        </TimelineDot>
                    </TimelineSeparator>
                    <TimelineContent>
                        <LogText>{t("words.created")}</LogText>
                    </TimelineContent>
                </TimelineItem>
            </Timeline>


        </Container>
    )
}

export default LogTimeline