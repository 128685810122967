import styled from "styled-components";

export const Container = styled.div`
    width: 1100px;
    height: 550px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 2px #00000029;
    border-radius: 10px;

    @media(max-height: 900px) {
        width: 800px;
        height: 400px;
    }

    @media(max-height: 750px) {
        width: 800px;
        height: 350px;
    }

    @media(max-width: 480px) {
        width: 380px;
        height: 350px;
    }
`;

export const H1 = styled.h1`
    font-family: 'Mulish';
    font-size: 30px;
    font-weight: 800;
    color: #0080FC;

    @media(max-height: 750px) {
        font-size: 20px;
    }

    @media(max-width: 480px) {
        font-size: 25px;
    }
    
`;

export const ErrorMsg = styled.span`
    color: #6E6E6E;
    font-size: 15px;
    font-weight: bold;
`;

export const Image = styled.div`
    .imgs {
        width: 400px;
        padding: 0;
    }

    .imgExcluded {
        width: 300px;
        padding: 0;
    }

    @media(max-height: 900px) {
        .imgs {
            width: 300px;
            margin-top: -50px;
            margin-bottom: -50px;
        };

        .imgExcluded {
            width: 300px;
            margin-top: -30px;
            margin-bottom: -40px;
        }
    }

    @media(max-height: 750px) {
        .imgs {
            width: 300px;
            margin-top: -50px;
            margin-bottom: -50px;
        };

        .imgExcluded {
            width: 250px;
            margin-top: -60px;
            margin-bottom: -60px;
        }
    }

    @media(max-width: 480px) {
        .imgs {
            width: 300px;
            margin-top: -50px;
            margin-bottom: -60px;
        };

        .imgExcluded {
            width: 250px;
            margin-top: -60px;
            margin-bottom: -60px;
        }
    }

`;  