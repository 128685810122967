import React from "react";
import SignImage from '../../../Assets/chat_img/sign.svg'
import { useTranslation } from "react-i18next";

import {
    Container,
    Input,
    Texts,
    Board,
    P,
} from './styles';
import isMobile from "../../../Services/isMobile";
import NoMatch from "../../../Common/Pages/404";

const LoginChat = () => {

    const [code, setCode] = React.useState("");
    const [complete, setComplete] = React.useState(false);
    const { t } = useTranslation();

    const loginChat = (code) => {
        const lt = document.getElementById('codeInput').value
        if (lt.length >= 8) {
            setComplete(true);
        } else {
            setComplete(false)
        }
    }

    const confirmLogin = (access_code) => {
        const code = access_code.toLowerCase();
        window.location.replace(`/attendant/login/${code}`);
    }

    return (
        <Container>
            {isMobile() ?
                <div>
                    <img src={SignImage} alt="logo" />
                    <Board>
                        <Texts>
                            <h3>{t("words.login")}</h3>
                        </Texts>
                        <P>
                            {t("words.use_code")}
                        </P>
                        <Input>
                            <input placeholder="Code" id="codeInput" type="text" value={code} onChange={(e) => { setCode(e.target.value.toUpperCase()); loginChat(code) }} maxLength={8} />
                        </Input>
                        <Texts>
                            {complete ? <button style={{ background: '#0080FC', cursor: 'pointer' }} onClick={() => confirmLogin(code)}>{t("words.login")}</button> : <button href="">{t("words.login")}</button>}
                        </Texts>
                    </Board>
                </div>
                :
                <NoMatch />
            }


        </Container>
    )
}

export default LoginChat