import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    text-align:center;
    align-items: center;
`;

export const Image = styled.div`
    margin-top: 5rem;
    width: 100%;
    align-items: center;
    text-align:center;
    > img{
        width: 25%;
    }
`;

export const WarningArea = styled.div`
    width: 70%;
    height: 65%;
    margin: auto;
    align-items: center;
    text-align: center;

    background: #FFFFFF;
    box-shadow: 0px 1px 2px #00000029;
    border-radius: 10px;
    color: #0080FC;

    .Texts {
        width: 50%;
        height: 100%;
        margin: auto;
        display: flex;
        flex-direction: column;

        @media(max-width: 800px){
            width: 100%;
        }

        > h1 {
            padding-top: 3rem;
            font-size: 65px;
            font-weight: 700;
        }

        > h3 {
            font-size: 30px;
            font-weight: 700;
            padding-bottom: 40px;
        }

        > a {
            padding: 12px;
            margin: 5px 0;
            background: #0080FC;
            box-shadow: 0.62px 0.79px 2px #1E120D1A;
            border-radius: 5px;
            opacity: 1;
            text-decoration: none;
            color: #fff;
            font-weight: 600;
        }
    }

`;