import React from 'react'
import { Container, H1, ErrorMsg, Image } from './styles'
import { ReactComponent as SomethingWrong } from '../../../Assets/icon/Connection Error.svg'
import { ReactComponent as UserLimit } from '../../../Assets/icon/Group User Limit.svg'
import { ReactComponent as ArchivedCampaign } from '../../../Assets/icon/Campaign Unavailable.svg'
import { ReactComponent as CampaignExluded } from '../../../Assets/icon/Campaign Group Excluded.svg'

const InviteError = ({ text, image }) => {

    return (
        <Container>
            <Image>
                {image === "something_wrong" && <SomethingWrong className='imgs' />}
                {image === "member_limit" && <UserLimit className='imgs' />}
                {image === "archived" && <ArchivedCampaign className='imgs' />}
                {image === "not_found" && <CampaignExluded className='imgExcluded' />}
            </Image>
            <H1>Oopss!</H1>
            <ErrorMsg>{text}</ErrorMsg>
        </Container>
    )
}

export default InviteError