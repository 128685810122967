import React from 'react';
import { Container, } from './styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { useTranslation } from "react-i18next";
import { useCommun } from '../../../Providers/commun';
import isMobile from '../../../Services/isMobile';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const Content = ({ children }) => {

    const { t } = useTranslation();
    const { isAsideOpen, isMobileState, setIsMobileState } = useCommun();
    const [open, setOpen] = React.useState(false);

    var url = window.location.href.split('/')[3];
    var urlInvite = window.location.href.split('/')[5];

    const handleClose = (reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    React.useEffect(() => {

    }, [isAsideOpen]);

    React.useEffect(() => {
        setIsMobileState(isMobile());
    }, []);

    function recarregarPagina() {
        setIsMobileState(isMobile());
    }

    var boraLa;
    window.onresize = function () {
        clearTimeout(boraLa);
        boraLa = setTimeout(recarregarPagina, 100);
    };

    return (
        <Container style={{ paddingLeft: isAsideOpen === true ? window.innerWidth > 800 && window.innerWidth < 1920 ? '230px' : '20%' : '', paddingRight: isAsideOpen === true ? window.innerWidth > 800 && window.innerWidth < 1920 ? '2%' : '16%' : '' }}>
            {children}
            <Snackbar open={open} /* autoHideDuration={3000} */ onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    {t("words.disconnected_phone")}
                </Alert>
            </Snackbar>
            {/*  {!isMobileState ? url !== "not_authorized" || urlInvite !== "invite" ? <BtnOverview /> : <></> : <></>} */}
        </Container>
    );
}

export default Content;