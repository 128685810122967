import React from 'react';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import PhoneIcon from '@material-ui/icons/Phone';
import pt from '../../../Assets/icon_flags/pt.svg';
import en from '../../../Assets/icon_flags/en.svg';
import es from '../../../Assets/icon_flags/es.svg';
import de from '../../../Assets/icon_flags/de.svg';
import fr from '../../../Assets/icon_flags/fr.svg';
import it from '../../../Assets/icon_flags/it.svg';
import ne from '../../../Assets/icon_flags/ne.svg';
import pl from '../../../Assets/icon_flags/pl.svg';
import sortSolid from '../../../Assets/CommunIcons/sort-solid.svg'
import ListItem from '@material-ui/core/ListItem';
import Popover from '@material-ui/core/Popover';
import { useTranslation } from "react-i18next";
import Avatar from '@material-ui/core/Avatar';
import TranslateIcon from '@material-ui/icons/Translate';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import * as OperatorService from '../../../Services/operator';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import isMobile from '../../../Services/isMobile';

import {
    Container,
    UseStyle,
    StyledMenu,
    StyledMenuItem,
    Box,
    Line,
} from './styles';


const ProfileIcon = ({ name, fone, info, image }) => {

    const changeLanguage = (language, fl) => {
        i18n.changeLanguage(language);
        setAnchorElMenu(null);
    };
    const { i18n, t } = useTranslation();
    const classe = UseStyle();

    const logout = () => {
        OperatorService.logout();
    }

    /* Popover */
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => { setAnchorEl(event.currentTarget); };
    const handleClose = () => { setAnchorEl(null); };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    /* Menu */
    const [anchorElMenu, setAnchorElMenu] = React.useState(null);
    const handleClickMenu = (event) => { setAnchorElMenu(event.currentTarget); };
    const handleCloseMenu = () => { setAnchorElMenu(null); };

    return (
        <Container>
            <Avatar onClick={handleClick} className={isMobile() && classe.small} alt={name} style={{ cursor: 'pointer', background: info !== "chat" && '#0080FC' }}>{name.split("", 1)[0]}</Avatar>
            <Popover id={id} open={open} anchorEl={anchorEl} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }} transformOrigin={{ vertical: 'top', horizontal: 'center', }}>
                <Box>
                    <Line>
                        {name}
                    </Line>
                    <div style={{ width: '100%', height: '1px', borderBottom: 'solid 1px #E3E8EB ' }} />
                    <Line>
                        {info === "chat" ? <><PhoneIcon style={{ color: '#779AF9', marginRight: '10px' }} /> {formatPhoneNumberIntl('+' + fone?.split('@')[0])}</> : <><PhoneIcon style={{ color: '#779AF9', marginRight: '10px' }} /> {fone}</>}
                    </Line>
                    <div style={{ width: '100%', height: '1px', borderBottom: 'solid 1px #E3E8EB ' }} />
                    {info === "chat" &&
                        <Line onClick={logout} style={{ cursor: 'pointer', }}>
                            <ExitToAppIcon style={{ color: '#779AF9', marginRight: '15px' }} />{t("words.disconnect")}
                        </Line>
                    }
                    {info === "chat" && <div style={{ width: '100%', height: '1px', borderBottom: 'solid 1px #E3E8EB ' }} />}
                    <Line style={{}}>
                        <div >
                            <TranslateIcon style={{ color: '#779AF9', marginRight: '15px' }} />{t("words.language")}
                        </div>
                    </Line>
                    <Line>
                        <div style={{ background: '#F2F2F2', borderRadius: '3px', display: 'flex', width: '100%', height: '45px', cursor: 'pointer', }} onClick={handleClickMenu}>

                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', padding: '5px' }}>
                                <div style={{ display: 'flex' }}>
                                    {localStorage.getItem('i18nextLng') === "pt" && <img alt="br" src={pt} style={{ width: '90%' }} />}
                                    {localStorage.getItem('i18nextLng') === "en" && <img alt="en" src={en} style={{ width: '90%' }} />}
                                    {localStorage.getItem('i18nextLng') === "de" && <img alt="de" src={de} style={{ width: '90%' }} />}
                                    {localStorage.getItem('i18nextLng') === "es" && <img alt="es" src={es} style={{ width: '90%' }} />}
                                    {localStorage.getItem('i18nextLng') === "fr" && <img alt="fr" src={fr} style={{ width: '90%' }} />}
                                    {localStorage.getItem('i18nextLng') === "it" && <img alt="br" src={it} style={{ width: '90%' }} />}
                                    {localStorage.getItem('i18nextLng') === "nl" && <img alt="nl" src={ne} style={{ width: '90%' }} />}
                                    {localStorage.getItem('i18nextLng') === "pl" && <img alt="pl" src={pl} style={{ width: '90%' }} />}
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', height: '100%', width: '40%' }}>
                                    {localStorage.getItem('i18nextLng') === "pt" && "Português"}
                                    {localStorage.getItem('i18nextLng') === "en" && "English"}
                                    {localStorage.getItem('i18nextLng') === "de" && "Deutsch"}
                                    {localStorage.getItem('i18nextLng') === "es" && "Espñol"}
                                    {localStorage.getItem('i18nextLng') === "fr" && "Français"}
                                    {localStorage.getItem('i18nextLng') === "it" && "Italiano"}
                                    {localStorage.getItem('i18nextLng') === "nl" && "Nederlands"}
                                    {localStorage.getItem('i18nextLng') === "pl" && "Polski"}
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', height: '100%', justifyContent: 'flex-end', width: '20%' }}><img src={sortSolid} alt="" /> </div>
                            </div>

                        </div>
                        <div>
                            <StyledMenu style={{ width: '50%' }} id="customized-menu" anchorEl={anchorElMenu} keepMounted open={Boolean(anchorElMenu)} onClose={handleCloseMenu}>
                                <StyledMenuItem onClick={() => changeLanguage("en", en)}>
                                    <ListItemIcon style={{ width: '5%' }}>
                                        <img alt="eua" src={en} style={{ width: '50%' }} />
                                    </ListItemIcon>
                                    <ListItemText primary="English" />
                                </StyledMenuItem>

                                <StyledMenuItem onClick={() => changeLanguage("de", de)}>
                                    <ListItemIcon style={{ width: '5%' }}>
                                        <img alt="de" src={de} style={{ width: '50%' }} />
                                    </ListItemIcon>
                                    <ListItemText primary="Deutsch" />
                                </StyledMenuItem>

                                <StyledMenuItem onClick={() => changeLanguage("es", es)}>
                                    <ListItemIcon style={{ width: '5%' }} >
                                        <img alt="br" src={es} style={{ width: '50%' }} />
                                    </ListItemIcon>
                                    <ListItemText primary="Espñol" />
                                </StyledMenuItem>

                                <StyledMenuItem button onClick={() => changeLanguage("pt", pt)}>
                                    <ListItemIcon style={{ width: '5%' }}>
                                        <img alt="br" src={pt} style={{ width: '50%' }} />
                                    </ListItemIcon>
                                    <ListItemText primary="Português" />
                                </StyledMenuItem>

                                <ListItem button onClick={() => changeLanguage("fr", fr)}>
                                    <ListItemIcon style={{ width: '5%' }}>
                                        <img alt="fr" src={fr} style={{ width: '50%' }} />
                                    </ListItemIcon>
                                    <ListItemText primary="Français" />
                                </ListItem>

                                <StyledMenuItem button onClick={() => changeLanguage("it", it)}>
                                    <ListItemIcon style={{ width: '5%' }}>
                                        <img alt="it" src={it} style={{ width: '50%' }} />
                                    </ListItemIcon>
                                    <ListItemText primary="Italiano" />
                                </StyledMenuItem>

                                <StyledMenuItem button onClick={() => changeLanguage("nl", ne)}>
                                    <ListItemIcon style={{ width: '5%' }}>
                                        <img alt="nl" src={ne} style={{ width: '50%' }} />
                                    </ListItemIcon>
                                    <ListItemText primary="Nederlands" />
                                </StyledMenuItem>

                                <StyledMenuItem button onClick={() => changeLanguage("pl", pl)}>
                                    <ListItemIcon style={{ width: '5%' }}>
                                        <img alt="pl" src={pl} style={{ width: '50%' }} />
                                    </ListItemIcon>
                                    <ListItemText primary="Polski" />
                                </StyledMenuItem>

                            </StyledMenu>
                        </div>
                    </Line>

                </Box>
            </Popover>
        </Container >
    )
}

export default ProfileIcon;