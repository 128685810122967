import React from "react";
import { ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { useTranslation } from "react-i18next";
import { Button } from '@material-ui/core';
import { cancelBtn2 } from "../../../Styles/buttons";


import {
    Container,
    CustomModal,
} from './styles';

const ModalComponent = ({ children, conetnt, title, button, action }) => {
    const { t } = useTranslation();
    const BtnCancel = cancelBtn2();

    const [modalState, setModalState] = React.useState(false);

    const toggleModal = () => {
        setModalState(!modalState)
    }

    return (
        <Container>
            <div onClick={toggleModal}>
                {button}
            </div>
            <CustomModal isOpen={modalState} toggle={toggleModal}>
                <ModalHeader>Dicas para evitar bloqueio de números novos</ModalHeader>
                <ModalBody>
                    {children}
                </ModalBody>
                <ModalFooter>
                    <div>
                        <Button classes={{ root: BtnCancel.root, label: BtnCancel.label }} onClick={toggleModal}>
                            {t("words.close")}
                        </Button>
                    </div>
                    <div>
                        {action}
                    </div>
                </ModalFooter>
            </CustomModal>
        </Container>
    )
}

export default ModalComponent;