import React from 'react';
import Disconnected from '../../../Assets/attendance/disconnected.svg'
import Alert from '@material-ui/lab/Alert';
import { useTranslation } from 'react-i18next';


import {
    Container,
    Image,
    WarningArea
} from './styles';

const Logout = () => {

    const { t } = useTranslation();

    return (
        <Container>
            <Image>
                <img src={Disconnected} alt="logo" />
            </Image>

            <WarningArea>
                <div className='Texts'>
                    <h1>{t("words.disconnected")}</h1>
                    <Alert severity="warning">
                        <div>{t("words.closed_session")}</div>
                    </Alert>
                </div>
            </WarningArea>


        </Container>
    )
}

export default Logout;