import React from 'react';
import { Avatar } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import * as DataFormat from '../../../Services/formartText';
import ws from '../../../ws';
import { useChat } from '../../../Providers/chat';
import Badge from '@material-ui/core/Badge';
import axios_base_chat from '../../../axios_base_chat';
import { useTranslation } from 'react-i18next';
/* import * as MessageService from '../../../Services/message'; */

import {
    Container,
    ChatList,
    ChatLines,
    ChatLine,
    ChatName,
    ChatDate,
    ChatMessage,
    UseStyle,
    OperatorNameTag,
} from './styles';
/* import axios from 'axios'; */


const ChatListItem = ({ name, phone, date, id, image, attendenceId, operatorId, OperadorName, lastMessage }) => {
    const location = useLocation();
    const history = useHistory();
    const classes = UseStyle();
    const { chat, setChatOpen } = useChat();
    const { t } = useTranslation();

    const [chatSelected, setChatSelected] = React.useState();
    const [newMessage, setNewMessage] = React.useState(0);
    const [lasMessageS, setLastMessage] = React.useState(lastMessage?.message?.conversation ? lastMessage?.message?.conversation : lastMessage?.message?.extendedTextMessage?.text);

    React.useEffect(() => {
        if (lastMessage?.message?.imageMessage) {
            setLastMessage(t("words.image"))
        } else if (lastMessage?.message?.videoMessage) {
            setLastMessage(t("words.video"))
        } else if (lastMessage?.message?.extendedTextMessage?.canonicalUrl) {
            setLastMessage(t("words.unknow"))
        } else if (lastMessage?.message?.audioMessage) {
            setLastMessage(t("words.audio"))
        } else if (lastMessage?.message?.viewOnceMessage || lastMessage?.message?.stickerMessage) {
            setLastMessage(t("words.unknow"))
        }
    }, [lastMessage])

    const selctChat = (id_) => {
        setChatSelected(id_);
        localStorage.setItem("contact", id_);
        localStorage.setItem("chat", attendenceId);
        localStorage.setItem("operatorId", operatorId);
        setChatOpen(true);
        history.push(`/attendant/chat`);
        setNewMessage(0)
    }

    React.useEffect(() => {
        setChatSelected(parseInt(localStorage.getItem('chat')));
    }, [location])

    React.useEffect(() =>
        ws.subscribe((msg) => {
            if (msg.subject === "message.received") {
                if (msg.data?.department_id === parseInt(chat.sectorID)) {
                    if (msg?.data?.id === attendenceId && parseInt(localStorage.getItem("chat")) !== msg?.data?.id) {
                        setNewMessage(1)
                    }
                    let paramSector = chat.sectorID;
                    if (!chat.sectorID) {
                        paramSector = localStorage.getItem('sectorActive');
                    }
                    axios_base_chat.get(`/chat/department/${paramSector}/attendance/${attendenceId}/message?limit=1`)
                        .then(res => {
                            setLastMessage(res.data[0].message.conversation ? res.data[0].message?.conversation : res.data[0].message.extendedTextMessage?.text);
                            if (res.data.message?.imageMessage || res.data.message?.viewOnceMessage) { setLastMessage(t("words.image")) }
                            if (res.data.message?.videoMessage) { setLastMessage(t("words.video")) }
                            if (res.data.message?.extendedTextMessage?.canonicalUrl) { setLastMessage(t("words.unknow")) }
                            if (res.data.message?.audioMessage) { setLastMessage(t("words.audio")) }
                            if (res.data.message?.viewOnceMessage || res.data.message?.stickerMessage) { setLastMessage(t("words.unknow")) }
                        })
                        .catch(err => {
                            if (err?.response?.data?.message === "record not found") {
                                setLastMessage("");
                            }
                        })
                }
            }
        }), [])

    return (
        <Container >
            <ChatList onClick={() => selctChat(id)} className={chatSelected === attendenceId && classes.root} >
                <Badge badgeContent={newMessage} color="error">
                    <Avatar alt={name === "" ? formatPhoneNumberIntl('+' + phone.split('@')[0]) : name} src={image} style={{ width: '50px', height: '50px', marginLeft: '15px' }} />
                </Badge>
                <ChatLines >
                    <ChatLine>
                        <ChatName>
                            <p>{name === "" ? formatPhoneNumberIntl('+' + phone.split('@')[0]) : name}</p>
                        </ChatName>
                        <ChatDate>
                            {dayjs(date).format('HH:mm')}
                        </ChatDate>
                    </ChatLine>
                    <ChatLine style={{justifyContent: 'end'}}>
                        <ChatMessage>
                            {!lasMessageS ? <p>{t("words.sync_message")}</p> : <p dangerouslySetInnerHTML={{ __html: DataFormat.formatText(lasMessageS, true) }} ></p>}
                        </ChatMessage>
                        <OperatorNameTag style={{maxWidth: '50%'}}>
                            <p> {OperadorName} </p>
                        </OperatorNameTag>
                    </ChatLine>
                </ChatLines>
            </ChatList>
        </Container>
    )
}

export default ChatListItem;