let deferredPrompt;

const initializeInstallPrompt = () => {
    window.addEventListener('beforeinstallprompt', (event) => {
        event.preventDefault(); // Impedir o prompt padrão de instalação

        // Salvar o objeto deferredPrompt para uso posterior
        deferredPrompt = event;
    });
};

export { initializeInstallPrompt, deferredPrompt };
