import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { formatPhoneNumberIntl } from 'react-phone-number-input'
import { InputGroup, InputGroupText, Input } from 'reactstrap';
import { useTranslation } from "react-i18next";
import { FixedSizeList } from 'react-window';
import { BsSearch } from 'react-icons/bs';
import React from 'react'
import MemberModal from '../MemberModal';
import { ButtonBlue } from '../../../Styles/buttons';
import exclamation_circle from '../../../Assets/CommunIcons/exclamation-circle.svg'
import * as CampaignFunction from '../../../Services/campaignFunctions';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import { useCommun } from '../../../Providers/commun';
import isMobile from '../../../Services/isMobile';
import { BiExport } from 'react-icons/bi';
import { LightTooltip } from '../../../Styles/styles';
import DateFormat from '../../../Common/Components/DateFormat';

import {
    Container,
    Header,
    Title,
    Search,
    DivFlex,
    DivName,
} from './styles';
import CardInfo from '../Mobile/CardInfo';


const CampaignMembers = ({ memberList, campaignID }) => {
    const [search, setSearch] = React.useState("")

    const { t } = useTranslation();
    const { isMobileState, setIsMobileState } = useCommun();

    const filteredMemberList = memberList.filter(v => v.number.split('@')[0].includes(search));

    const [isToSearch, setIsToSearch] = React.useState(false);

    const [exporting, setExporting] = React.useState(false);

    const exportLeads = async () => {
        setExporting(true);
        const LeadsExport = await CampaignFunction.exportLeadas(campaignID);
        axios({
            url: `${process.env.REACT_APP_LINK_API}/storage/${LeadsExport.filename}`,
            method: 'GET',
            responseType: 'blob'
        })
            .then((response) => {
                const url = window.URL
                    .createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url.split(",").join(";");
                link.setAttribute('download', 'leads.csv');
                document.body.appendChild(link);
                link.click();
                setTimeout(() => {
                    setExporting(false);
                }, 3000);
            })
            .catch((err) => {
                setExporting(false);
            })
    }
    React.useEffect(() => {
        setIsMobileState(isMobile());
    }, [])

    function recarregarPagina() {
        setIsMobileState(isMobile());
    }

    var boraLa;
    window.onresize = function () {
        clearTimeout(boraLa);
        boraLa = setTimeout(recarregarPagina, 100);
    };

    const setSearchF = () => {
        setIsToSearch(!isToSearch);
        setTimeout(() => {
            const text = document.getElementById("inputId");
            text?.focus();
        }, 100);
    }

    return (
        <Container>
            <Header>
                {isToSearch ? <InputGroupText>
                    <Input className="form-input" id="inputId" onChange={e => setSearch(e.target.value)} value={search} placeholder={t("words.search")} />
                </InputGroupText> :
                    <div style={{ display: 'flex' }}>
                        <Title>{t("words.leads")}</Title>
                        <LightTooltip title={t("words.leads_list_description")} placement="top">
                            <img alt='info' src={exclamation_circle} width="21px" style={{ cursor: 'pointer' }} />
                        </LightTooltip>
                    </div>
                }

                <Search>
                    {isMobileState ? <InputGroupText style={{ marginRight: '15px' }} onClick={() => setSearchF()}><BsSearch /></InputGroupText> :
                        <InputGroup style={{ paddingRight: '15px', flex: '1', width: '170px' }}>
                            <InputGroupText><BsSearch /></InputGroupText>
                            <Input className="form-input" id="input" onChange={e => setSearch(e.target.value)} value={search} placeholder={t("words.search")} />
                        </InputGroup>
                    }

                    <ButtonBlue onClick={exportLeads}>{isMobileState ? <BiExport /> : t("words.export_leads")} {exporting === true && <CircularProgress color='inherit' size={25} style={{ marginLeft: '10px' }} />}</ButtonBlue>
                </Search>
            </Header>
            {isMobileState ? <TableContainer style={{ height: '350px', overflow: 'auto' }}>
                {filteredMemberList.map((member) => (
                    <CardInfo translate={t("words.joined")} img={member?.name !== "" && member?.name} src={`${process.env.REACT_APP_LINK_API}/storage/${member?.image}`} name={member?.name !== "" ? member?.name : t("words.name_unavailable")}
                        createdAt={member.created_at} desc={formatPhoneNumberIntl('+' + member.number?.split('@')[0])} info={member.is_admin === true ? (t("words.administrator")) : (t("words.lead"))} />

                ))} </TableContainer> :
                <TableContainer component={Paper} sx={{ height: '50%' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t("words.name")}</TableCell>
                                <TableCell>{t("words.number")}</TableCell>
                                <TableCell>{t("words.joined")}</TableCell>
                                <TableCell>{t("words.role")}</TableCell>
                                <TableCell style={{ width: '10%' }}></TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>
                    <FixedSizeList
                        height={filteredMemberList.length <= 3 ? 200 : 350}
                        itemCount={filteredMemberList.length}
                        itemSize={65}
                    >
                        {({ index, style }) => {
                            const member = filteredMemberList[index]
                            return (
                                <>
                                    <Table style={style} key={member?.id}>
                                        <TableBody >
                                            <TableRow >
                                                <TableCell style={{ maxWidth: '10px' }}><DivFlex ><div><Avatar alt={member?.name !== "" && member?.name} src={!member?.image ? "" : `${member?.image}`} /></div><DivName>{member?.name !== "" && member?.name}{member?.name === "" && t("words.name_unavailable")}</DivName></DivFlex></TableCell>
                                                <TableCell>{formatPhoneNumberIntl('+' + member.number?.split('@')[0])}</TableCell>
                                                <TableCell style={{ fontSize: '0.9rem', maxWidth: '10px' }}><DivName style={{ width: '100%' }}><DateFormat date={member.created_at} /></DivName> </TableCell>
                                                <TableCell>{member.is_admin === true ? (t("words.administrator")) : (t("words.lead"))}</TableCell>
                                                <TableCell style={{ width: '10%' }}>
                                                    <MemberModal campaignID={campaignID} selectedMember={member.id} />
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </>
                            )
                        }}
                    </FixedSizeList>
                </TableContainer>}
        </Container>
    )
}

export default CampaignMembers