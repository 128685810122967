import React from 'react'
import cover from '../../../Assets/chat_img/Chat start illustration.svg'
import { useTranslation } from 'react-i18next';

import {
    Container,
} from './styles';

const ChatIndex = () => {

    const { t } = useTranslation();

    return (
        <Container>
            <img src={cover} alt="" />
            <div>
                <h3>{t("words.synced_whats")}</h3>
            </div>
        </Container>
    )
}

export default ChatIndex;