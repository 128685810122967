import { Container, CardContainer, GraphicContainer, TopCards, BottomCards, Circle, Card, Title, SubTitle, GraphicTitle, Div1, Div2, DivNumber } from './styles'
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { getStatistics } from '../../../Services/campaignFunctions';
import AdmImg from '../../../Assets/icon/Administrators.svg'
import GroupImg from '../../../Assets/icon/Groups.svg'
import LeadsImg from '../../../Assets/icon/Leads.svg'
import MsgImg from '../../../Assets/icon/Message.svg'
import { useTranslation } from 'react-i18next';
import CountUp from 'react-countup';
import moment from 'moment';
import React from 'react'


const CampaignGraphics = ({ campaignID }) => {
    const [statistics, setStatistics] = React.useState([])

    const { t } = useTranslation();

    React.useEffect(() => {
        const getCampaignStatistics = async () => {
            const stats = await getStatistics(campaignID)
            setStatistics(stats)
        }
        getCampaignStatistics()
    }, [campaignID])

    return (
        <Container>
            <CardContainer>
                <TopCards>
                    <Card>
                        <Div1>
                            <div>
                                <Circle colors={['#2AF598', '#08AEEA']}>
                                    <img src={LeadsImg} alt="leads" />
                                </Circle>
                            </div>
                            <DivNumber> <SubTitle ><CountUp end={statistics?.totals?.member_count} duration={1.75} /></SubTitle></DivNumber>
                        </Div1>
                        <Div2>
                            <Title>{t("words.leads")}</Title>
                        </Div2>
                    </Card>
                    <Card>
                        <Div1>
                            <div>
                                <Circle colors={['#0AFFF7', '#05BCFC']}>
                                    <img src={GroupImg} alt="groups" />
                                </Circle>
                            </div>
                            <DivNumber><SubTitle ><CountUp end={statistics?.totals?.group_count} duration={1.75} /></SubTitle></DivNumber>
                        </Div1>
                        <Div2>
                            <Title>{t("words.groups")}</Title>
                        </Div2>
                    </Card>
                </TopCards>
                <BottomCards>
                    <Card>
                        <Div1>
                            <div>
                                <Circle colors={['#0294FF', '#7E1AFD']}>
                                    <img src={MsgImg} alt="message" />
                                </Circle>
                            </div>
                            <DivNumber><SubTitle ><CountUp end={statistics?.totals?.message_count} duration={1.75} /></SubTitle></DivNumber>
                        </Div1>
                        <Div2>
                            <Title>{t("words.total_msg")}</Title>
                        </Div2>
                    </Card>
                    <Card>
                        <Div1>
                            <div>
                                <Circle colors={['#09C6F9', '#045DE9']}>
                                    <img src={AdmImg} alt="admin" />
                                </Circle>
                            </div>
                            <DivNumber><SubTitle ><CountUp end={statistics?.totals?.admin_count} duration={1.75} /></SubTitle></DivNumber>
                        </Div1>
                        <Div2>
                            <Title>{t("words.adm")}</Title>
                        </Div2>
                    </Card>
                </BottomCards>
            </CardContainer>
            <GraphicContainer>
                <GraphicTitle><h4>{t("words.member_per_day")}</h4></GraphicTitle>
                <ResponsiveContainer width="100%" height="90%">
                    <BarChart
                        width={500}
                        height={300}
                        data={statistics?.member_count_by_date?.map(stat => ({
                            name: moment().subtract(stat.index, 'days').format(localStorage.getItem('i18nextLng') === "en" ? 'MM/DD' : 'DD/MM'),
                            joined: stat.count
                        })).reverse()}
                        margin={{
                            top: 20,
                            right: 30,
                            left: -10,
                            bottom: 10,
                        }}
                        barSize={20}
                    >
                        <XAxis dataKey="name" scale="point" padding={{ left: 10, right: 10 }} />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="joined" fill="#0294FF" radius={[4, 4, 0, 0]} background={{ fill: 'White' }} />
                    </BarChart>
                </ResponsiveContainer>
            </GraphicContainer>
        </Container>
    )
}

export default CampaignGraphics