import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from 'react-i18next';
import {
    Container,
    Small,
    Small2
} from './styles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
        display: 'flex',
        alignItems: 'center'
    },
}));


const CardNumber = ({ phone, options, createdAt, name, info }) => {

    const { t } = useTranslation();

    const classes = useStyles();
    return (
        <Container>
            <div>
                <Accordion style={{ border: '1px solid #DEE3EA' }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}>{phone} &nbsp; &nbsp; &nbsp; <Small>{options}</Small></Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography style={{ flexDirection: 'column', paddingBottom: '15px' }}>
                            <Small2>{name}</Small2>
                            <br />
                            <Small>{t("words.created")}</Small>
                            <br />
                            <Small2>{createdAt}</Small2>
                            <br />
                        </Typography>
                        <div>
                            {info}
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
        </Container>
    )
};

export default CardNumber;