import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

export const Container = styled.div`
    overflow-y: scroll;
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    font-family: 'Mulish';
    height: 100vh;
    width: 100vw ;
    padding: 15px 0 ;

    > p {
        color: #6E6E6E;
        font-size: 15px;
    }
`;

export const Image = styled.div`
    width: 150px;
    height: 150px;
    
`;

export const NameGroup = styled.div`text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    > h5 {
        font-size: 18px;
        font-weight: bold;
        color: #6E6E6E;
    }

    > div {
        > p {
            color: #6E6E6E;
            font-size: 15px;
        }
    }

`;

export const SectionOne = styled.div`
     
`;

export const UseStyles = makeStyles((theme) => ({
    root: {
        width: 200,

    },
    margin: {
        height: theme.spacing(3),
    },
    container: {
        display: 'grid',
        gridTemplateColumns: 'repeat(12)',
        gridGap: theme.spacing(1),
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        whiteSpace: 'nowrap',
        marginBottom: theme.spacing(1),
    },
    divider: {
        margin: theme.spacing(2, 0),
    },
}));


export const JoinContainer = styled.div`
    width: 1100px;
    height: 30rem;
    background: #FFFFFF;
    box-shadow: 0px 1px 2px #00000029;
    border-radius: 10px;

    @media(max-width: 768px) {
        width: 750px;
    }

    @media(max-height: 768px){
        height: 25rem;
    }
    @media(max-width: 480px) {
        width: 400px;
        height: 450px;
    }
`;

export const JoinQuestion = styled.h5`
    padding-top: 25px;
    font-size: 25px;
    font-weight: 900;
    color: #6E6E6E;

    @media(max-width: 1500px) {
        padding-top: 70px;
    }

    @media(max-width: 768px) {
        padding-top: 50px;
    }

    @media(max-width: 480px) {
        padding-top: 20px;
    }
`;

export const Box = styled.div`
    height: 20rem;
    width: 224px;
    background: #FFFF;
    box-shadow: 0px 2px 3px #00000026;
    border-radius: 10px;
    margin: 45px;

    @media(max-height: 768px){
        height: 15rem;
    }

    @media(max-width: 480px) {
        margin: 15px;
    }
    
`;

export const Boxes = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 0.3rem;
`

export const ImgDiv = styled.div`
    width: 184px;
    height: 8rem;
    background: #F5FAFF;
    border: 1px solid #CAE2FC;
    border-radius: 5px;
    margin: 20px auto 30px auto;

    > img {
        margin-top: 10px;
    }

    @media(max-width: 480px) {
        width: 150px;
    }
`;

export const Or = styled.span`
    font-size: 16px;
    font-weight: bold;
    color: #6E6E6EBF;
    margin-top: 195px;

    @media(max-width: 480px) {
        margin-top: 120px;
    }
`;