import { archiveCamp, deleteCamp } from '../../../Services/campaignFunctions';
import { ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap'
import { cancelBtn2, saveBtn2 } from '../../../Styles/buttons';
import { useTranslation } from "react-i18next";
import { Button } from '@material-ui/core';
import { ArchiveModal, DeleteModal } from './styles';
import React from 'react'
import AlertNotify from '../../../Common/Components/Alert';
import { useHistory } from 'react-router-dom';

const CampaignOptions = ({ getCampaignDetails, campaign, archiveModal, toggleArchiveModal, toggleDeleteModal, deleteModal }) => {

    const [archiveSuccess, setArchiveSuccess] = React.useState(false)
    const [unarchiveSuccess, setUnarchiveSuccess] = React.useState(false)
    const [comparedName, setComparedName] = React.useState()

    const { t } = useTranslation();
    const history = useHistory();

    const SaveBtn = saveBtn2()
    const CancelBtn = cancelBtn2()

    const ArchiveCampaign = async () => {
        var data = {
            "archived": !campaign.archived,
        };
        await archiveCamp(campaign.id, data)
        toggleArchiveModal()
        if (campaign.archived) {
            setUnarchiveSuccess(true)
        } else {
            setArchiveSuccess(true)
        }
        getCampaignDetails()
    }

    const DeleteCampaign = async () => {
        await deleteCamp(campaign.id)
        toggleDeleteModal()
        history.push(`/campaigns/dashboard`);
    }

    return (
        <>
            <ArchiveModal isOpen={archiveModal} toggle={toggleArchiveModal}>
                <ModalHeader>{campaign.archived ? t("words.unarchive") : t("words.archive")}</ModalHeader>
                <ModalBody>
                    {campaign.archived ? <h5>{t("words.unarchive_question")}</h5> : <h5>{t("words.archive_question")}</h5>}
                </ModalBody>
                <ModalFooter>
                    <div>
                        <Button classes={{ root: CancelBtn.root, label: CancelBtn.label, }} onClick={toggleArchiveModal}>{t("words.cancel")}</Button>
                    </div>
                    <div>
                        <Button classes={{ root: SaveBtn.root, label: SaveBtn.label, }} onClick={ArchiveCampaign} >{t("words.save")}</Button>
                    </div>
                </ModalFooter>
            </ArchiveModal>

            <DeleteModal isOpen={deleteModal} toggle={toggleDeleteModal}>
                <ModalHeader>{t("words.delete")}</ModalHeader>
                <ModalBody>
                    <h5>{t("words.delete_campaign")}</h5>
                    <h5>{t("words.delete_cp2")}</h5>
                    <p>{t("words.delete_cp3")} <b style={{ color: 'red' }}>{campaign.name}</b></p>
                    <Input className="form-input" id="input-delete" placeholder={t("words.delete")} value={comparedName} onChange={e => setComparedName(e.target.value)} />
                </ModalBody>
                <ModalFooter>
                    <div>
                        <Button classes={{ root: CancelBtn.root, label: CancelBtn.label, }} onClick={toggleDeleteModal}>{t("words.cancel")}</Button>
                    </div>
                    {comparedName === campaign.name ?
                        <div >
                            <Button classes={{ root: SaveBtn.root, label: SaveBtn.label, }} onClick={DeleteCampaign}>{t("words.delete")}</Button>
                        </div>
                        : null}
                </ModalFooter>
            </DeleteModal>


            {archiveSuccess === true && <AlertNotify message="Campanha arquivada com sucesso" state={true} time={3000} type="success" />}
            {unarchiveSuccess === true && <AlertNotify message="Campanha desarquivada com sucesso" state={true} time={3000} type="success" />}
        </>
    )
}

export default CampaignOptions