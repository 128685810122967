import styled from "styled-components";
import { Modal } from 'reactstrap';

export const CustomModal = styled(Modal)`
    .modal-content {
        padding: 1.25rem;
        width: 40.625rem;
        background: #F3F7FF 0% 0% no-repeat padding-box;
        box-shadow: 0px 14px 32px #00000040;
        border-radius: 10px;
        border: 0;

        font-family: 'Mulish';
        color: #434343;
    }
    
    .modal-title {
        font-size: 18px;
        font-weight: bold;
    }    

    .modal-header{
        border-bottom: 0;
    }

    .modal-footer{
        border-top: 0;
    }

    .textFields{
        font-family: 'Mulish';
        font-size: 15px;
        color: #434343;
    }
`;

export const DetailsDiv = styled.div`
    display: flex;
    width: 100%;
    height: 9.375rem;
    background: #FFFFFF;
    border: 1px solid #D0DAED;
    border-radius: 3px;

    .MuiAvatar-root {
        width: 5.5rem;
        height: 5.5rem;
        margin: 1.875rem;
        padding: 0.75rem;
        background-color: #FFFFFF;
        border: 1px solid #D5E8FA;
    }
    
    .MuiAvatar-fallback {
        color: #BDBDBD;
    }

    .MuiAvatar-img {
        width: 150%;
        height: 150%;
    }

    .css-1okebmr-indicatorSeparator{
        background-color: #BED1EA;
        margin-bottom: 50px;
        margin-top: 38px;
        width: 1.5px;
    }
`;

export const LeftDiv = styled.div`
    width: 20%;
`;

export const RightDiv = styled.div`
    width: 40%;
`;

export const Infos = styled.div`
    width: 100%;
    color: #6E6E6E;
    padding: 40px;
    font-size: 15px;
    font-weight: 600;
    margin: 0 auto;
    text-align: left;
    display: flex;
    flex-direction: column;
`;

export const TagsDiv = styled.div`
    width: 100%;
    max-height: 15.7rem;
    background: #FFFFFF;
    border: 1px solid #D0DAED;
    border-radius: 3px;
    padding: 20px;
    
    .css-yk16xz-control {
        background: #FAFBFC 0% 0% no-repeat padding-box;
        border: 1px solid #D0DAED;
        border-radius: 4px;
        opacity: 1;
        font-family: Mulish;
    }

    .css-yk16xz-control:hover, .css-1pahdxg-control:hover, .css-1pahdxg-control {
        border-color: #D0DAED;
    }
    
    .css-1pahdxg-control {
        box-shadow: 0 0 1px  #D0DAED;
    }

    .css-1okebmr-indicatorSeparator{
        display: none;
    }

    .css-1rhbuit-multiValue {
        background-color: #FFFFFF;
        border: 1px solid #D0DAED;
        border-radius: 5px;
        padding: 2px;
        justify-content: center;
        align-items: center;
    }

    .css-12jo7m5 {
        color: #425EF7;
        font-weight: bold;
    }

    .css-xb97g8 {
        background-color: rgba(184, 197, 214, .64);
        margin-top: 1px;
        margin-left: 2px;
        margin-right: 2px;
        padding: 1px;
        color: #FFFFFF;
        border-radius: 100%;
        
        &:hover {
            background-color: rgba(184, 197, 214, 1);
            color: #FFFFFF;
        }
    }

`;

export const Title = styled.h6`
    margin-top: 1.25rem;
    color: #6E6E6E;
    font-size: 16px;
    font-weight: 600;
`;

export const SubTitle = styled.h6`
    margin-top: 1.25rem;
    color: #6E6E6E;
    font-size: 15px;
    font-weight: 600;
`;

export const TagBtnDiv = styled.div`
    display: flex;
    justify-content: right;
    padding-top: 40px;
`;

export const NewTagDiv = styled.div`
    height: 5.625rem;
    background: #FFFFFF;
    border: 1px solid #D0DAED;
    border-radius: 3px;
    padding: 25px;
`;
