import React from 'react';
import back from '../../../Assets/icon/new 404.svg';
import { useTranslation } from "react-i18next";

import {
    Container,
    Image,
    WarningArea
} from './styles';


const NoMatch = ({ from }) => {
    const { t } = useTranslation();
    return (
        <Container>
            <Image>
                <img src={back} alt="Imagem"></img>
            </Image>
            <WarningArea>
                <div className='Texts'>
                    <h1>404</h1>
                    <h3>{t("words.404")}</h3>
                    {from === "campaign" ? <div /> : <a href='https://office.builderall.com/br/office/onboarding'>{t("words.back_office")}</a>}
                    <a href='/'>Login</a>
                </div>
            </WarningArea>
        </Container>
    )
}

export default NoMatch;