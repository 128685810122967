import { withStyles } from "@material-ui/core";
import styled from "styled-components";
import Menu from '@material-ui/core/Menu';

export const StyledMenu = withStyles({
    paper: {
        boxShadow: ' 0px 0px 5px #26242459',
        borderRadius: '10px'
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

export const Container = styled.div`    
    ::-webkit-scrollbar {
       width: 8px;
   }

   ::-webkit-scrollbar-track {
       padding-right: 10px;
   }

   ::-webkit-scrollbar-thumb {
       background-color: #BABABA;
       border-radius: 5px;
   }
`;

export const BackBtn = styled.div`
    display: flex;
    justify-content: right;
    margin-bottom: 2.5rem;

    .DashLink {
        text-decoration: none;
    }
`;

export const HeaderBtns = styled.div`
    display: flex;
    justify-content: right;
    align-items: center;

    .CampIcon {
        padding-right: 0.625rem;
    }

    .UnarchiveIcon {
        margin-right: 0.625rem;
        font-size: 22px;
    }

    .CopyBtn {
        padding: 0 1.25rem;
        margin-right: 1rem;
    }

    .SettingsBtn {
        min-width: 2.625rem;
        max-width: 2.625rem;
    }

    @media(max-width: 800px){
        width: 90vw;
        margin-top: 10%;
    }
`;

export const DivItemMenu = styled.div`
    text-align: left;
    justify-content: center;
    width: 100px;
    font: normal normal 600 1rem Mulish;
    letter-spacing: 0px;
    color: #656565;
    text-transform: capitalize;
`;
