import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import './i18n';
import { ChatProvider } from './Providers/chat';
import { CommunProvider } from './Providers/commun';
import { AppProvider } from './Providers/app';
import { SessionProvider } from './Providers/session';
import "./sentry";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

const url = window.location.href.split('/')[3];

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={url !== "attendant" ? <div style={{ textAlign: 'center', height: '50vh', marginTop: '50vh' }}>
      <div className="spinner-grow text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div> : <div ></div>}>
      <AppProvider>
        <SessionProvider>
          <CommunProvider>
            <ChatProvider>
              <App />
            </ChatProvider>
          </CommunProvider>
        </SessionProvider>
      </AppProvider>
    </Suspense >
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
