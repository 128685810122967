import React from 'react';
import OTPauth from '../../../Assets/attendance/OTPauthentication.svg';
import logo from '../../../Assets/chat_img/Loading Chat.svg';
import LiquidLoader from '../../../Animations/LiquidLoader';
import { useChat } from '../../../Providers/chat';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { cancelBtn2 } from '../../../Styles/buttons';
import WS from '../../../ws';
import axios from 'axios';
import {
    Container,
    Image,
    WarningArea,
    buttonComun,
    buttonStyled,
    Texts,
    UseStyle,
} from './styles'
import AlertNotify from '../../../Common/Components/Alert';
import { InstallButton } from '../../Components/InstallButon';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Button } from '@material-ui/core';
import { deferredPrompt } from '../../../installPrompt';
import isMobile from '../../../Services/isMobile';


const AttendantLogin = () => {


    const history = useHistory();
    const btnCancel = cancelBtn2();
    let { access_code } = useParams();
    const { t } = useTranslation();
    const { setChat } = useChat();
    const classes = UseStyle();

    const [twoSteps, setTwoSteps] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [validationToken, setValidationToken] = React.useState();
    const [code1, setCode1] = React.useState();
    const [code2, setCode2] = React.useState();
    const [code3, setCode3] = React.useState();
    const [code4, setCode4] = React.useState();
    const [code5, setCode5] = React.useState();
    const [code6, setCode6] = React.useState();
    const [number, setMumber] = React.useState();

    const operatorLogin = async () => {
        try {
            var data = JSON.stringify({
                "access_code": access_code
            });
            var config = { method: 'post', url: `${process.env.REACT_APP_LINK_API}/chat/login`, headers: { 'Content-Type': 'application/json' }, data: data };
            const { data: res } = await axios(config)
            setValidationToken(res.validation_token);
            setMumber(res?.number)
            setTwoSteps(true);
        } catch (error) {
            localStorage.setItem('tryAgain', `/attendant/login/${access_code}`)
            history.push('/attendant/disconnected');
        }
    }
    const checkCode = async () => {
        try {
            setTwoSteps(false);
            var data = JSON.stringify({
                "access_code": access_code,
                "validation_token": validationToken,
                "validation_code": `${code1}${code2}${code3}${code4}${code5}${code6}`,
            });
            var config = { method: 'post', url: `${process.env.REACT_APP_LINK_API}/chat/login`, headers: { 'Content-Type': 'application/json' }, data: data };
            const { data: res } = await axios(config)
            localStorage.setItem('operator_token', res.token);
            setChat({ operatorToken: res.token });
            localStorage.setItem("chat_status", "close")
            WS.init(res.token, true);
            setTimeout(() => {
                window.location.replace('/attendant/chat');
            }, 2000);
        } catch (error) {
            setTwoSteps(true);
            setError(true);
            setTimeout(() => {
                setError(false);
            }, 6000);
        }
    }

    const sendCodeAgain = () => {
        alert(t("words.sent_new_code"));
        operatorLogin()
    }
    const noAction = () => { }

    React.useEffect(() => {
        operatorLogin();
    }, [access_code])

    const [installChat, setInstallChat] = React.useState(false);

    const installToggle = () => {
        setInstallChat(!installChat);
    }

    /* React.useEffect(() => {
        installToggle();
    }, []); */

    const isPWAInstalled = () => {
        if (window.matchMedia('(display-mode: standalone)').matches) {
            // A aplicação está em modo standalone (instalada como PWA)
            return true;
        } else if (window.navigator.standalone || (window.matchMedia('(display-mode: fullscreen)').matches && /iphone|ipod|ipad|android/i.test(window.navigator.userAgent))) {
            // A aplicação está em modo standalone (instalada como PWA) em alguns dispositivos iOS ou Android
            return true;
        } else {
            // A aplicação não está instalada como PWA
            return false;
        }
    };

    React.useEffect(() => {
        const handler = (e) => {
            e.preventDefault();
            if (isMobile()) {
                if (isPWAInstalled()) {
                    setInstallChat(false)
                } else {
                    if (localStorage.getItem("notShow") === "true") {
                    } else {
                        setInstallChat(true)
                    }
                }
            }
        };

        window.addEventListener('beforeinstallprompt', handler);

        return () => window.removeEventListener('beforeinstallprompt', handler);
    }, []);

    const notShowAgain = () => {
        localStorage.setItem("notShow", "true");
        installToggle();
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            if (code6) {
                checkCode()
            } else {
                noAction()
            }
        }
    }

    return (
        <Container>
            <Image>
                {!twoSteps ? <img src={logo} alt="logo" /> : <img src={OTPauth} alt="logo" />}
            </Image>
            {!twoSteps ?
                <WarningArea >
                    <div className='Texts'>
                        <LiquidLoader />
                        <h3>{t("words.loading_chat")}</h3>
                    </div>
                </WarningArea>
                :
                <WarningArea >
                    <Texts>
                        <h3>{t("words.two_factor_auth")}</h3>
                        <div style={{ width: '54%', textAlign: 'center', margin: 'auto' }}> <p>{t("words.text_describe1")} (**) *****-**{number}. {t("words.text_describe2")}.</p> </div>
                        <form className='Inputs'>
                            <div className='Input'><input type='text' id="code1" maxlength="1" autocomplete="off"
                                onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '0'); e.nativeEvent.inputType === "deleteContentBackward" || e.nativeEvent.inputType === "deleteContentBackward" ? document.getElementById("code1").select() : document.getElementById("code2").select(); }}
                                onChange={(e) => setCode1(e.target.value)} />
                            </div>
                            <div className='Input'><input type='text' id="code2" maxlength="1" autocomplete="off"
                                onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '0'); e.nativeEvent.inputType === "deleteContentBackward" || e.nativeEvent.inputType === "deleteContentBackward" ? document.getElementById("code1").select() : document.getElementById("code3").select(); }}
                                onChange={(e) => setCode2(e.target.value)} />
                            </div>
                            <div className='Input'><input type='text' id="code3" maxlength="1" autocomplete="off"
                                onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '0'); e.nativeEvent.inputType === "deleteContentBackward" || e.nativeEvent.inputType === "deleteContentBackward" ? document.getElementById("code2").select() : document.getElementById("code4").select(); }}
                                onChange={(e) => setCode3(e.target.value)} />
                            </div>
                            <div className='Input'></div>
                            <div className='Input'><input type='text' id="code4" maxlength="1" autocomplete="off"
                                onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '0'); e.nativeEvent.inputType === "deleteContentBackward" || e.nativeEvent.inputType === "deleteContentBackward" ? document.getElementById("code3").select() : document.getElementById("code5").select(); }}
                                onChange={(e) => setCode4(e.target.value)} />
                            </div>
                            <div className='Input'><input type='text' id="code5" maxlength="1" autocomplete="off"
                                onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '0'); e.nativeEvent.inputType === "deleteContentBackward" || e.nativeEvent.inputType === "deleteContentBackward" ? document.getElementById("code4").select() : document.getElementById("code6").select(); }}
                                onChange={(e) => setCode5(e.target.value)} />
                            </div>
                            <div className='Input'><input type='text' id="code6" maxlength="1" autocomplete="off"
                                onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '0'); e.nativeEvent.inputType === "deleteContentBackward" || e.nativeEvent.inputType === "deleteContentBackward" ? document.getElementById("code5").select() : document.getElementById("code6").focus(); }}
                                onChange={(e) => setCode6(e.target.value)}
                                onKeyPress={handleKeyPress} />
                            </div>
                        </form>
                        <button onClick={() => { !code6 ? noAction() : checkCode() }} style={!code6 ? buttonComun : buttonStyled}>{t("words.validade_code")}</button>
                    </Texts>
                    <p onClick={() => sendCodeAgain()} >{t("words.didnt_receive")}</p>
                </WarningArea>
            }
            {error !== false && <AlertNotify type="error" time={6000} message={t("words.code_mismatch")} state={true} />}

            <Modal isOpen={installChat} toggle={installToggle} className={classes.modal} >
                <ModalHeader className={classes.backgroundColor}><span style={{ font: "normal normal bold 20px/26px", color: "#434343", textTransform: "uppercase", }}>{t("words.install_app")}</span></ModalHeader>
                <ModalBody>
                    <p>{t("words.install_question")}</p>
                </ModalBody>

                <ModalFooter className={classes.backgroundColor}>
                    <div>
                        <Button classes={{ root: btnCancel.root, label: btnCancel.label, }} onClick={() => notShowAgain()}>
                            {t("words.cancel")}
                        </Button>
                    </div>
                    <div >
                        <InstallButton deferredPrompt={deferredPrompt} afterInstall={installToggle} origen="poupUp" />
                    </div>

                </ModalFooter>
            </Modal>
        </Container>
    )
}

export default AttendantLogin;