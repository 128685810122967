import styled from 'styled-components';

export const Container = styled.div`

`;

export const BtnConfigs = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    .btnWhats{
        margin-right: 20px;
        background-color: #0080FC;
    }

    .btnWhats:hover{
        background-color: #0080FC;
    }
    
    .whatsIcon{
        color: #FFFFFF;
    }

`;

