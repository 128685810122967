import React from 'react';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import WhatsAppIconMenu from '../../../../Assets/IconesChat/WhatsApp  icon Menu.svg';
import { LightTooltip } from '../../../../Styles/styles';
import finishedConversantion from '../../../../Assets/IconesChat/FinishedConversations.svg';
import contactsInformation from '../../../../Assets/IconesChat/Contact.svg';
import attendantes from '../../../../Assets/IconesChat/Attendants.svg';
import { useTranslation } from "react-i18next";
import { ReactComponent as ExitToAppIcon } from '../../../../Assets/icon/Disconnect from this user.svg';
import { ReactComponent as VolumeOffIcon } from '../../../../Assets/icon/audio off.svg';
import { ReactComponent as VolumeUpIcon } from '../../../../Assets/icon/audio on.svg';
import { ReactComponent as NotificationsIcon } from '../../../../Assets/icon/Bell.svg';
import GetAppIcon from '@material-ui/icons/GetApp';

import {
    Container,
    useStyles,
    GlobalCss,
    TopDiv
} from './styled';
import { InstallButton } from '../../InstallButon';
import isMobile from '../../../../Services/isMobile';
import { deferredPrompt } from '../../../../installPrompt';


const DrawerComponent = ({ operators, contacts, finishedList, notifySound, notifyAlert, notifySoundFunc, logoutClick }) => {

    const { t } = useTranslation();
    const classes = useStyles();
    const [state, setState] = React.useState({ bottom: false, });
    const [showInstall, setShowInstall] = React.useState(false);

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };
    const isPWAInstalled = () => {
        if (window.matchMedia('(display-mode: standalone)').matches) {
            // A aplicação está em modo standalone (instalada como PWA)
            return true;
        } else if (window.navigator.standalone || (window.matchMedia('(display-mode: fullscreen)').matches && /iphone|ipod|ipad|android/i.test(window.navigator.userAgent))) {
            // A aplicação está em modo standalone (instalada como PWA) em alguns dispositivos iOS ou Android
            return true;
        } else {
            // A aplicação não está instalada como PWA
            return false;
        }
    };

    React.useEffect(() => {
        const handler = (e) => {
            e.preventDefault();
            if (isMobile()) {
                if (isPWAInstalled()) {
                    setShowInstall(false)
                } else {
                    setShowInstall(true)
                }
            }
        };

        window.addEventListener('beforeinstallprompt', handler);

        return () => window.removeEventListener('beforeinstallprompt', handler);
    }, []);

    return (
        <Container>
            <Button onClick={toggleDrawer("bottom", true)}><img alt="config" src={WhatsAppIconMenu} /></Button>

            <Drawer anchor="bottom" open={state["bottom"]} onClose={toggleDrawer("bottom", false)}>
                <GlobalCss />
                <div className={clsx(classes.list, classes.fullList)} role="presentation" onClick={toggleDrawer("bottom", false)} onKeyDown={toggleDrawer("bottom", false)}>
                    <TopDiv >
                        <div style={{ background: '#DCE4EF 0% 0% no-repeat padding-box', borderRadius: '2px', width: '31px', height: '3px', marginBottom: '15px' }}></div>
                        <small>{t("words.menu")}</small>
                    </TopDiv>
                    <Divider style={{ margin: '0 3%' }} />
                    <List >
                        <LightTooltip title={t("words.operators")} placement="top">
                            <ListItem button onClick={operators}>
                                <ListItemIcon><img src={attendantes} alt="" /></ListItemIcon>
                                <ListItemText primary={t("words.operators")} />
                            </ListItem>
                        </LightTooltip >

                        <LightTooltip title={t("words.contacts")} placement="top">
                            <ListItem button onClick={contacts}>
                                <ListItemIcon><img src={contactsInformation} alt="" /></ListItemIcon>
                                <ListItemText primary={t("words.contacts")} />
                            </ListItem>
                        </LightTooltip >

                        {/*   <LightTooltip title={t("words.start_conversation")} placement="top">
                            <ListItem button>
                                <ListItemIcon><NewChat /></ListItemIcon>
                                <ListItemText primary={t("words.start_conversation")} />
                            </ListItem>
                        </LightTooltip > */}

                        <LightTooltip title={t("words.finished_att")} placement="top">
                            <ListItem button onClick={finishedList}>
                                <ListItemIcon><img src={finishedConversantion} alt="" />
                                </ListItemIcon>
                                <ListItemText primary={t("words.finished_att")} />
                            </ListItem>
                        </LightTooltip >
                    </List>
                    <Divider style={{ margin: '0 3%' }} />
                    <div style={{ font: 'normal normal 600 1.1rem Mulish', letterSpacing: '0px', color: '#434343', paddingLeft: '3%', margin: '4% 0' }}>
                        <samll>{t("words.settings")}</samll>
                    </div>
                    <Divider style={{ margin: '0 3%' }} />
                    <List>

                        {showInstall && <ListItem button onClick={notifySoundFunc}>
                            <ListItemIcon>
                                <GetAppIcon style={{ color: '#0F7BFF' }} />
                            </ListItemIcon>
                            <ListItemText primary={<InstallButton deferredPrompt={deferredPrompt} origen={"menu"} />} />
                        </ListItem>}

                        <ListItem button onClick={notifySoundFunc}>
                            <ListItemIcon>
                                {notifySound === true ? <VolumeOffIcon /> : <VolumeUpIcon />}
                            </ListItemIcon>
                            <ListItemText primary={notifySound === true ? t("words.dis_notification") : t("words.active_notification")} />
                        </ListItem>

                        <ListItem button onClick={notifyAlert}>
                            <ListItemIcon>
                                <NotificationsIcon />
                            </ListItemIcon>
                            <ListItemText primary={t("words.disable_alerts")} />
                        </ListItem>

                        <ListItem button onClick={logoutClick}>
                            <ListItemIcon>
                                <ExitToAppIcon />
                            </ListItemIcon>
                            <ListItemText primary={t("words.disconnect_user")} />
                        </ListItem>

                    </List>
                </div >
            </Drawer>
        </Container>
    )
}

export default DrawerComponent;