import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

export const Container = styled.div`
    grid-area: MH;
    color:  ${props => props.theme.color.white};
    display: flex;
    align-items: center;
    padding: 0 10px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0 1px 2px rgb(38 36 36 / 14%);
    border: 1px solid #dedede;
    opacity: 1;
    @media(max-width: 600px){
        padding: 0;
        width: 100vw;
    }
`;
export const Left = styled.div`
    display: flex;
    width: 50%;
    height: 100%;
    align-items: center;
`;
export const MenuButton = styled.div`
    align-items: center;
    display: flex;
    background: #F4F4F4 0% 0% no-repeat padding-box;
    width: 120px;
    padding: 0px 10px;
    height: 100%;
    border: 2px solid #EFEFEF;
`;
export const Right = styled.div`
    color:  ${props => props.theme.color.white};
    background: #FFFFFF 0% 0% no-repeat padding-box;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 10px;
    width: 50%;
`;
export const Add = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    color: ${props => props.theme.color.white};
`;

export const Logo = styled.img`
    padding-left: 10px;
    height: 25px;
    cursor: pointer;
   
`;

export const Notify = styled.span`
     display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    padding-right: 15px;
    cursor: pointer;
`;
export const UseStyle = makeStyles((theme) => ({
    typography: {
        padding: theme.spacing(2),
    },
    root: {
        display: 'flex',
        '& > *': {
            padding: theme.spacing(1),
        },
        width: '100%',
        maxWidth: '20vw',
        backgroundColor: theme.palette.background.paper,
    },
    roots: {
        display: 'flex',
        '& > *': {
            padding: theme.spacing(1),
        },
        width: '100%',
        maxWidth: '40vw',
        backgroundColor: theme.palette.background.paper,
    },
    Menu: {
        backgroundColor: '#0294FF',
        color: '#fff'
    },
    padding: {
        padding: '0 5px',
    },
}));

export const HR = styled.div`
    height: 30px;
    width: 3px;
    border-left: 1px solid #BED1EA;
    opacity: 1;
    padding-right: 20px;
`;