import React from 'react';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useTranslation } from "react-i18next";
import ChatTransfer from '../../ChatTransfer';
import WhatsAppIconMenu from '../../../../Assets/IconesChat/WhatsApp  icon Menu.svg';
import contactsInformation from '../../../../Assets/IconesChat/ContactInformation.svg';
import NewContact from '../../NewContact';

import {
    Container,
    useStyles,
    GlobalCss,
    TopDiv,
    Small
} from './styled';

const DrawerConversationSettings = ({ contactInfo, contactId }) => {

    const { t } = useTranslation();
    const classes = useStyles();
    const [state, setState] = React.useState({ bottom: false, });
    const [contacInfo, setCntactInfo] = React.useState(false);

    const toggleDrawer = (anchor, open, cont) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [anchor]: open });
        setCntactInfo(cont);
        const draw = document?.getElementById('Drawer');
        if (!draw) {
            return;
        } else {
            draw.hidden = false;
        }
    };

    const hiddenFunc = () => {
        const draw = document?.getElementById('Drawer');
        draw.hidden = true;
    }
    return (
        <Container>
            <Button onClick={toggleDrawer("bottom", true, false)}><img alt="config" src={WhatsAppIconMenu} /></Button>

            <Drawer anchor="bottom" open={state["bottom"]} onClose={toggleDrawer("bottom", false)} id="Drawer" >
                <GlobalCss />
                <div className={clsx(classes.list, classes.fullList)} role="presentation" onClick={() => { contacInfo === false && toggleDrawer("bottom", false) }} /* onKeyDown={toggleDrawer("bottom", false, false)} */>
                    <TopDiv >
                        <div style={{ background: '#DCE4EF 0% 0% no-repeat padding-box', borderRadius: '2px', width: '31px', height: '3px', marginBottom: '15px' }}></div>
                        <small>{contacInfo === true ? t("words.contact_info") : t("words.options")}</small>
                    </TopDiv>
                    <Divider style={{ margin: '0 3%' }} />
                    {contacInfo === true ?
                        <div style={{ padding: '20px' }}>
                            <div style={{ padding: '10px', height: '150px', overflow: 'auto' }}>
                                <Small>
                                    {contactInfo}
                                </Small>
                            </div>
                            <div onClick={() => { toggleDrawer("bottom", true, true); hiddenFunc() }} style={{ paddingTop: '10px' }}>
                                <NewContact who="view" id={contactId} open="true" device="mobile" />
                            </div>
                        </div>
                        :
                        <List>
                            <div onClick={() => { toggleDrawer("bottom", true, true); hiddenFunc() }}>
                                <ChatTransfer device="mobile" />
                            </div>
                            <ListItem button onClick={() => setCntactInfo(true)}>
                                <ListItemIcon> <img src={contactsInformation} alt=" " /></ListItemIcon>
                                <ListItemText primary={t("words.contact_info")} />
                            </ListItem>
                        </List>
                    }
                </div >
            </Drawer>
        </Container>
    )
}

export default DrawerConversationSettings;