import React from 'react';
import { Grid, } from './styles';
import MainHeader from '../MainHeader';
import Content from '../Content';
import Aside from '../Aside';
import { useCommun } from '../../../Providers/commun';
import isMobile from '../../../Services/isMobile';
import AsideMobile from '../Mobile/AsideMobile';

const Layout = ({ children }) => {

  const isOnInviteScreen = /^\/campaign\/(.*)\/invite\/?$/.test(window.location.pathname);

  const { screenWidth, setScreenWidth, setScreenHeight } = useCommun();
  const [tokenCad, setTokenCad] = React.useState(localStorage.getItem('primaryToken'))

  React.useEffect(() => {
    setScreenHeight(window.innerHeight);
    setScreenWidth(window.innerWidth);
  }, []);

  React.useEffect(() => {
    setTokenCad(localStorage.getItem('primaryToken'))
  }, [localStorage.getItem('primaryToken')])

  if (isOnInviteScreen) return (
    <>
      {children}
    </>
  );

  return (
    <>
      <builderall-appshell id="appshell" token={tokenCad} tool="whatsapp-central">
        <Grid className='ba-app-content' >
          <Content>
            {children}
          </Content>
          {screenWidth <= 600 && isMobile() ? <AsideMobile /> : <Aside />}
        </Grid>
      </builderall-appshell>
    </>

  );
}

export default Layout;