import React from 'react';
import Help_Active from '../../../../Assets/CommunIcons/Help  Active - Hover.svg';
import Help_Inactive from '../../../../Assets/CommunIcons/Help  Inactive.svg';
import Attendance_Active from '../../../../Assets/CommunIcons/Icon menu Attendance _ Active.svg';
import Attendance_Inactive from '../../../../Assets/CommunIcons/Icon menu Attendance _ Inactive.svg';
import Campaign_Active from '../../../../Assets/CommunIcons/Icon menu Campaign _ Active.svg';
import Campaign_Inactive from '../../../../Assets/CommunIcons/Icon menu Campaign _ Inactive.svg';
import Number_Active from '../../../../Assets/CommunIcons/Icon menu My Numbers_ _ Active.svg';
import Number_Inactive from '../../../../Assets/CommunIcons/Icon menu My Numbers _ Inactive.svg';
import { useTranslation } from 'react-i18next';
import { useCommun } from '../../../../Providers/commun';
import { Link, useLocation } from 'react-router-dom';
import AttendanceMenu from './AttendanceMenu';

import {
    Container,
    Iten,
    Line,
} from './styles';
import useApp from '../../../../Providers/app';


function AsideMobile() {

    const location = useLocation();
    const { t } = useTranslation();
    const { setIsAsideOpen, isAsideOpen } = useCommun();
    const app = useApp();

    const [asideOpen, setAsideOpen] = React.useState(false);

    const [launch, setLaunch] = React.useState(false);
    const [attendance, setAttendance] = React.useState(false);
    const [phone, setphone] = React.useState(false);
    const [back, setBack] = React.useState(false);
    const [help, setHelp] = React.useState(false);

    const open = () => {
        setAsideOpen(!asideOpen);
        setIsAsideOpen(!asideOpen);
    }

    const url = window.location.href.split('/')[3];

    React.useEffect(() => {
        setLaunch(false);
        setAttendance(false);
        setphone(false);
        if (url === "dashboard") {
            setLaunch(true);
        }
        if (url === "adminDashboard") {
            setAttendance(true);
        }
        if (url === "myNumbers") {
            setphone(true);
        }
    }, [location, url])

    return (
        <Container style={{ width: isAsideOpen === true ? "15vw" : "0px", visibility: !isAsideOpen && 'hidden' }}>
            <Line>
                <Link to="/campaigns/dashboard" style={{ textDecoration: 'none' }} onClick={() => setIsAsideOpen(false)} >
                    <Iten style={{ justifyContent: asideOpen !== true && 'center', background: url === "dashboard" && '#F4F7FC', color: url === "dashboard" && '#0F7BFF', borderLeft: url === "dashboard" && '3px solid #0F7BFF' }} onMouseOver={() => setLaunch(true)} onMouseLeave={() => url !== "dashboard" && setLaunch(false)}>
                        <img alt="campaing" src={launch !== true ? Campaign_Inactive : Campaign_Active} />
                        {asideOpen === true && <div >{t("words.campaigns")}</div>}
                    </Iten>
                </Link>

                <AttendanceMenu>
                    <Iten onClick={() => setIsAsideOpen(false)} style={{ justifyContent: asideOpen !== true && 'center', background: url === "adminDashboard" && '#F4F7FC', color: url === "adminDashboard" && '#0F7BFF', borderLeft: url === "adminDashboard" && '3px solid #0F7BFF' }} onMouseOver={() => setAttendance(true)} onMouseLeave={() => url !== "adminDashboard" && setAttendance(false)}>
                        <img alt="Attendance" src={attendance !== true ? Attendance_Inactive : Attendance_Active} />
                        {asideOpen === true && <div >{t("words.attendance")}</div>}
                    </Iten>
                </AttendanceMenu>

                <Link to="/numbers" style={{ textDecoration: 'none' }} onClick={() => setIsAsideOpen(false)}>
                    <Iten style={{ justifyContent: asideOpen !== true && 'center', background: url === "myNumbers" && '#F4F7FC', color: url === "myNumbers" && '#0F7BFF', borderLeft: url === "myNumbers" && '3px solid #0F7BFF' }} onMouseOver={() => setphone(true)} onMouseLeave={() => url !== "myNumbers" && setphone(false)}>
                        <img alt="my_number" src={phone !== true ? Number_Inactive : Number_Active} />
                        {asideOpen === true && <div >{t("words.my_numbers")}</div>}
                    </Iten>
                </Link>
            </Line>

            <Line style={{ borderTop: '1px solid #BED1EA' }}>
                <a href={app.business.url} target="_blank" rel='noreferrer' style={{ textDecoration: 'none', color: 'white' }} onClick={() => setIsAsideOpen(false)} >
                    <Iten style={{ justifyContent: asideOpen !== true && 'center' }} onMouseOver={() => setBack(true)} onMouseLeave={() => setBack(false)}>
                        <img width={30} alt={app.business.name} src={app.business.logo} />
                        {asideOpen === true && <div >{app.business.name}</div>}
                    </Iten>
                </a>

                {app.support && <a href={app.support} target="_blank" rel='noreferrer' style={{ textDecoration: 'none', color: 'white' }} onClick={() => setIsAsideOpen(false)} >
                    <Iten style={{ justifyContent: asideOpen !== true && 'center' }} onMouseOver={() => setHelp(true)} onMouseLeave={() => setHelp(false)}>
                        <img alt="help" src={help !== true ? Help_Inactive : Help_Active} />
                        {asideOpen === true && <div >Ajuda</div>}
                    </Iten>
                </a>}
            </Line>
        </Container>
    );
}

export default AsideMobile;