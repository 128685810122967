import Illustration_Attention from '../../../Assets/icon/Illustration_Attention.png';
import Close from '../../../Assets/attendance/Cloese.svg';
import { BtnBlue2 } from '../../../Styles/buttons';
import { useTranslation } from 'react-i18next';
import { Modal, ModalBody } from 'reactstrap';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Container, ImgDiv, TextDiv, CloseDiv, SubtitleDiv, BtnDiv } from './styles'
import React from 'react'

const NumberErrorModal = ({ toggle, isOpen }) => {

    const btn = BtnBlue2();
    const { t } = useTranslation();

    return (
        <Container>
            <Modal isOpen={isOpen} toggle={toggle} style={{ maxWidth: '770px', borderRadius: '6px', padding: '20px 20px' }} >
                <CloseDiv>
                    <img alt="close" src={Close} onClick={toggle} />
                </CloseDiv>
                <TextDiv>
                    <h1>{t("words.attention")}</h1>
                    <h3>{t("words.your_number_disconnected")}</h3>
                </TextDiv>
                <ModalBody>
                    <ImgDiv>
                        <img alt="Atention" src={Illustration_Attention} />
                    </ImgDiv>
                    <SubtitleDiv>
                        <p>{t("words.connect_your_number")}</p>
                    </SubtitleDiv>
                </ModalBody>
                <BtnDiv>
                    <Link to="/numbers" style={{ textDecoration: "none", }}><Button className={btn.root} >{t("words.my_numbers")}</Button></Link>
                </BtnDiv>
            </Modal>
        </Container>
    )
}

export default NumberErrorModal