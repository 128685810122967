import React from 'react';

import {
    Container,
    ChatList,
    ChatName,
    ChatMessage,
    Btn,
} from './styles';


const ChatSchedulingMessageList = ({ data, icon, icon2 }) => {
    return (
        <Container >
            <ChatList >
                <div style={{ width: '80%', padding: '20px' }}>
                    <ChatName>
                        <p>{data.name}</p>
                    </ChatName>
                    <ChatMessage>
                        <p>{data.type !== "text" ? "Arquivo Anexado" : data.content}</p>
                    </ChatMessage>
                </div>
                <div style={{ flex: '1', padding: '20px', display: 'flex' }}>
                    <Btn>
                        {icon}
                    </Btn>
                    <Btn>
                        {icon2}
                    </Btn>
                </div>
            </ChatList>
        </Container>
    )
}

export default ChatSchedulingMessageList;