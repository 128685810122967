import React from 'react';
import * as DataFormat from '../../../Services/formartText';
import dayjs from 'dayjs';
import { useChat } from '../../../Providers/chat';
import Backdrop from '@material-ui/core/Backdrop';
import ImageIcon from '@material-ui/icons/Image';
import GetAppIcon from '@material-ui/icons/GetApp';
import CircularProgress from '@material-ui/core/CircularProgress';
import VideocamIcon from '@material-ui/icons/Videocam';
import AudiotrackIcon from '@material-ui/icons/Audiotrack';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { useTranslation } from 'react-i18next';
import axios_base_chat from '../../../axios_base_chat';

import {
  Container,
  Item,
  Text,
  Date,
  Message,
  SttylesMessage
} from './styles';

const ChatMessageItem = ({ data, origin, type }) => {

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const [donwloading, setDonwloading] = React.useState(false)

  const { chat } = useChat();
  const { t } = useTranslation();
  const classes = SttylesMessage();


  const [imagePath, setImagePath] = React.useState();
  const [msgType, setImgType] = React.useState();

  const download = (id, type) => {
    setDonwloading(true);
    setImgType(type);
    axios_base_chat.get(`chat/department/${chat.sectorID}/attendance/${parseInt(localStorage.getItem("chat"))}/message/${id}/download`)
      .then(res => {
        setImagePath(`${process.env.REACT_APP_LINK_API}/storage/${res.data.filename}`);
        setOpen(true);
        setDonwloading(false);
      })
      .catch(err => {

      })
  }
  const openLink = (link) => {
    window.open(link);
  }

  return (
    <Container>
      <Message style={{
        justifyContent: data?.key?.fromMe === true ? 'flex-end' : 'flex-start',
      }}>
        <Item className={data?.key?.fromMe === true ? classes.My : classes.Their} style={{}}>

          {data.message?.imageMessage &&
            <div style={{ height: '50px', width: '250px', cursor: "pointer", paddingBottom: '5px', paddingTop: '15px', display: 'flex', color: data?.key?.fromMe === true ? '#fff' : '#999' }} >
              {/* {donwloading === false ? <GetAppIcon fontSize='large' style={{ marginRight: '10px' }}  onClick={() => download(data.key?.id, "image")}  /> :
                <div style={{ marginRight: '15px', marginLeft: '15px' }}> <CircularProgress size={25} disableShrink /> </div>} */}
              <ImageIcon style={{ marginLeft: '10px' }} />
              {/*  {t("words.download")}  */} {t("words.image")}
            </div>
          }
          {data.message?.viewOnceMessage &&
            <div style={{ height: '50px', width: '250px', cursor: "pointer", paddingBottom: '5px', paddingTop: '15px', display: 'flex', color: data?.key?.fromMe === true ? '#fff' : '#999' }} >
              {donwloading === false ? <VisibilityOffIcon style={{ marginRight: '10px' }} /* onClick={() => download(data.key?.id, "viewOnceMessage")} */ /> :
                <div style={{ marginRight: '15px', marginLeft: '15px' }}> <CircularProgress size={25} disableShrink /> </div>}
              <ImageIcon style={{ marginLeft: '10px' }} />
              {t("words.image")} {t("words.unavailable")}
            </div>
          }
          {data.message?.stickerMessage &&
            <div style={{ height: '50px', width: '250px', cursor: "pointer", paddingBottom: '5px', paddingTop: '15px', display: 'flex', color: data?.key?.fromMe === true ? '#fff' : '#999' }} >
              {/* {donwloading === false ? <VisibilityOffIcon style={{ marginRight: '10px' }} onClick={() => download(data.key?.id, "viewOnceMessage")} /> :
                <div style={{ marginRight: '15px', marginLeft: '15px' }}> <CircularProgress size={25} disableShrink /> </div>} */}
              <ImageIcon style={{ marginLeft: '10px' }} />
              {t("words.unavailable")}
            </div>
          }
          {data.message?.viewOnceMessageV2 &&
            <div style={{ height: '50px', width: '250px', cursor: "pointer", paddingBottom: '5px', paddingTop: '15px', display: 'flex', color: data?.key?.fromMe === true ? '#fff' : '#999' }} >
              {/* {donwloading === false ? <VisibilityOffIcon style={{ marginRight: '10px' }} onClick={() => download(data.key?.id, "viewOnceMessage")} /> :
                <div style={{ marginRight: '15px', marginLeft: '15px' }}> <CircularProgress size={25} disableShrink /> </div>} */}
              <ImageIcon style={{ marginLeft: '10px' }} />
              {t("words.unavailable")}
            </div>
          }
          {data.message?.ptvMessage &&
            <div style={{ height: '50px', width: '250px', cursor: "pointer", paddingBottom: '5px', paddingTop: '15px', display: 'flex', color: data?.key?.fromMe === true ? '#fff' : '#999' }} >
              {/* {donwloading === false ? <VisibilityOffIcon style={{ marginRight: '10px' }} onClick={() => download(data.key?.id, "viewOnceMessage")} /> :
                <div style={{ marginRight: '15px', marginLeft: '15px' }}> <CircularProgress size={25} disableShrink /> </div>} */}
              <ImageIcon style={{ marginLeft: '10px' }} />
              {t("words.unavailable")}
            </div>
          }
          {data.message?.videoMessage &&
            <div style={{ height: '50px', width: '250px', cursor: "pointer", paddingBottom: '5px', paddingTop: '15px', display: 'flex', color: data?.key?.fromMe === true ? '#fff' : '#999' }} >
              {/*  {donwloading === false ? <GetAppIcon fontSize='large' style={{ color: data?.key?.fromMe === true ? '#fff' : '#999', marginRight: '10px' }} onClick={() => download(data.key?.id, "video")} /> :
                <div style={{ marginRight: '15px', marginLeft: '15px' }}> <CircularProgress size={25} disableShrink /> </div>} */}
              <VideocamIcon style={{ marginLeft: '10px' }} />
              {/*   {t("words.download")} */} {t("words.video")}

            </div>
          }

          {data.message?.extendedTextMessage?.canonicalUrl &&
            <div style={{ height: '250px', width: '250px', cursor: "pointer", paddingBottom: '5px' }} onClick={() => openLink(data.message?.extendedTextMessage.canonicalUrl)}>
              <img style={{ height: '90%', width: '100%', borderRadius: '6px' }} src={`data:image/png;base64, ${data.message?.extendedTextMessage.jpegThumbnail}`} alt={data.message?.extendedTextMessage.title} />
              <Text dangerouslySetInnerHTML={{ __html: DataFormat.formatText(data.message?.extendedTextMessage.title) }} style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', marginTop: '15px' }}>
              </Text>
            </div>
          }
          {data.message?.audioMessage &&
            <div style={{ height: '50px', width: '250px', cursor: "pointer", paddingBottom: '5px', paddingTop: '15px', display: 'flex', color: data?.key?.fromMe === true ? '#fff' : '#999' }} >
              {/*  {donwloading === false ? <GetAppIcon fontSize='large' style={{ color: data?.key?.fromMe === true ? '#fff' : '#999', marginRight: '10px' }} onClick={() => download(data.key?.id, "audio")} /> :
                <div style={{ marginRight: '15px', marginLeft: '15px' }}> <CircularProgress size={25} disableShrink /> </div>} */}
              <AudiotrackIcon style={{ marginLeft: '10px' }} />
              {/*  {t("words.download")}  */} {t("words.audio")}

            </div>
          }
          {data.message?.buttonsMessage &&
            <div>
              <Text dangerouslySetInnerHTML={{ __html: DataFormat.formatText(data.message.conversation ? data.message?.conversation : data.message.buttonsMessage?.contentText) }}></Text>
              <div style={{ background: '#FFF', color: '#202020', borderRadius: '6px', padding: '5px' }}>
                {data.message.buttonsMessage.buttons.map((button) =>
                  <Text> {button.buttonText.displayText}</Text>
                )}
              </div>
            </div>
          }

          {data.message?.buttonsResponseMessage &&
            <div>
              <div style={{ padding: '5px', borderRadius: '10px', backgroundColor: data?.key?.fromMe === true && '#FFF' }} className={data?.key?.fromMe === true ? classes.Their : classes.My}><p dangerouslySetInnerHTML={{ __html: DataFormat.formatText(data.message.conversation ? data.message?.conversation : data?.message?.buttonsResponseMessage?.contextInfo?.quotedMessage?.buttonsMessage?.contentText) }}></p></div>
              <Text dangerouslySetInnerHTML={{ __html: DataFormat.formatText(data?.message?.buttonsResponseMessage?.selectedDisplayText) }}></Text>

            </div>
          }

          {data.message?.extendedTextMessage?.contextInfo?.quotedMessage &&
            <div style={{}}>
              <div style={{ padding: '5px', borderRadius: '10px', backgroundColor: data?.key?.fromMe === true && '#FFF' }} className={data?.key?.fromMe === true ? classes.Their : classes.My}><p dangerouslySetInnerHTML={{ __html: DataFormat.formatText(data.message?.extendedTextMessage?.contextInfo?.quotedMessage?.conversation) }}></p></div>
            </div>
          }
          {data.message &&
            <Text dangerouslySetInnerHTML={{ __html: DataFormat.formatText(data.message.conversation ? data.message?.conversation : data.message.extendedTextMessage?.text) }}></Text>
          }
          <Date style={{ color: data?.key?.fromMe === true ? "#FFFF" : "#202020" }}>
            {dayjs(data.sent_at).format('HH:mm')}
          </Date>
        </Item>
      </Message>

      <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
        {msgType === "image" && <img alt="file" src={imagePath} style={{ maxWidth: '99%', maxHeight: '90%', }} />}
        {msgType === "video" && <video src={imagePath} controls style={{ maxWidth: '99%', maxHeight: '90%', }}></video>}
        {msgType === "audio" && <audio controls src={imagePath} style={{ height: '35px', border: 'none' }}></audio>}


      </Backdrop>

    </Container >
  )
}

export default ChatMessageItem;