import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';

export const cancelBtn2 = makeStyles({
    root: {
        background: 'transparent',
        '&:hover': {
            backgroundColor: 'transparent',
        },
        borderRadius: 0,
        border: 0,
        color: '#5A5959',
        height: 35,
        padding: '0',
        fontFamily: 'Mulish',
        fontSize: '13px',
        fontWeight: 'bold',
        marginRight: '10px'
    },
    label: {

    },

});

export const saveBtn2 = makeStyles({
    root: {
        background: '#01D4B9 0% 0% no-repeat padding-box',
        '&:hover': {
            backgroundColor: '#01D4B9',
            /* opacity: '0.8',
            transition: '0.5s', */
        },
        borderRadius: 5,
        border: 0,
        color: 'white',
        height: 35,
        padding: '0px 30px',
        fontWeight: 'bold',
        fontFamily: 'Lato'
    },
    label: {

    },

});

export const BtnGray2 = makeStyles({
    root: {
        backgroundColor: '#5A5959',
        '&:hover': {
            backgroundColor: '#5A5959',
            /* opacity: '0.8',
            transition: '0.5s', */
        },
        borderRadius: 5,
        border: 0,
        color: 'white',
        height: 45,
        fontWeight: 'bold'

    },
    label: {

    },

});

export const ButtonBlue = withStyles({
    root: {
        background: 'linear-gradient(45deg, #0080FC 50%, #0080FC  90%)',
        borderRadius: 5,
        border: 0,
        color: '#FFFFFF',
        height: 42,
        padding: '0px 30px',
        fontWeight: 'bold',
        fontFamily: 'Mulish',
        fontSize: '0.8rem',
        "&:disabled": {
            background: '#5A5959',
            color: '#FFFFFF',
        }
    },
})(Button);

export const BtnBlue2 = makeStyles({
    root: {
        background: 'linear-gradient(45deg, #0080FC 50%, #0080FC  90%)',
        borderRadius: 5,
        border: 0,
        color: 'white',
        height: 35,
        fontSize: '0.8rem',
        padding: '0px 30px',
        fontWeight: 'bold',
        fontFamily: 'Lato',
        "&:disabled": {
            background: '#5A5959',
            color: '#FFFFFF',
        }
    },
});


export const NewTag = makeStyles({
    root: {
        background: 'linear-gradient(45deg, #0080FC 50%, #0080FC  90%)',
        borderRadius: 5,
        border: 0,
        color: '#FFFFFF',
        height: 35,
        padding: '0px 10px',
        fontWeight: 'bold',
        fontFamily: 'Lato',
    },
});

export const FinishAttButton = makeStyles({
    root: {
        backgroundColor: '#EAF4FF',
        '&:hover': {
            backgroundColor: '#D1E3F2',
        },
        borderRadius: '5px',
        border: 0,
        color: '#0F7BFF',
        fontFamily: 'Mulish',
        fontWeight: '700',
        height: '40px',
        padding: '10px 20px',
        marginLeft: '10px',
        boxShadow: "0px 1px 1px #044B9026",
    },
    label: {

    },

});

export const BtnWhite = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: '#FAFAFA',
        },
        backgroundColor: '#FAFAFA',
        borderRadius: 5,
        border: 0,
        color: 'white',
        height: 45,
        width: 68,
    },
    label: {

    },

});

export const BtnWhiteSmall = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: '#FAFAFA',
        },
        backgroundColor: '#FAFAFA',
        borderRadius: 5,
        border: 0,
        color: 'white',
        height: 45,
        width: 50,
    },
    label: {

    },

});

export const BootstrapButton = withStyles({
    root: {
        backgroundColor: '#FAFAFA',
        borderRadius: 5,
        border: 0,
        color: '#434343',
        height: 35,
        padding: '0px 30px',
        fontWeight: 'bold',
        fontFamily: 'Mulish',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: '#FAFAFA',
        },
    },
})(Button);

export const BtnWhite2 = makeStyles({
    root: {
        backgroundColor: '#FAFAFA',
        '&:hover': {
            backgroundColor: '#FAFAFA',
        },
        borderRadius: 5,
        border: 0,
        color: '#434343',
        height: 35,
        padding: '0px 30px',
        fontWeight: 'bold',
        fontFamily: 'Mulish',
        textTransform: 'capitalize',
    },
});

export const ConnectedBtn = makeStyles({
    root: {
        background: 'linear-gradient(45deg, #0080FC 50%, #0080FC  90%)',
        borderRadius: 3,
        border: 0,
        color: '#FFFFFF',
        height: 35,
        paddingLeft: '16px',
        fontWeight: 'bold',
        fontFamily: 'Lato',
    },
});

export const DisconnectedBnt = makeStyles({
    root: {
        background: 'linear-gradient(45deg, #FAFBFC 50%, #FAFBFC  90%)',
        borderRadius: 3,
        border: '1px solid #DDDDDD',
        color: '#656565',
        height: 35,
        fontWeight: 'bold',
        fontFamily: 'Lato',
    },
});

export const BtnRed = makeStyles({
    root: {
        background: 'linear-gradient(45deg, #FA4B57 50%, #FA4B57  90%)',
        borderRadius: 5,
        border: 0,
        color: 'white',
        height: 35,
        padding: '0px 30px',
        fontWeight: 'bold',
        fontFamily: 'Lato',
    },
});

export const DesignButton = styled.button`
    width: auto;
    height: 37px;
    padding-left: 10px;
    padding-right: 10px;
    font: normal normal normal 13px/16px Muli;
    background-color: #1665D8;
    letter-spacing: 0px;
    border-radius: 6px;
`;