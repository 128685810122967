import React from 'react';
import back from '../../../Assets/icon/new 401.svg';
import { useTranslation } from "react-i18next";
import {
    Container,
    Image,
    WarningArea
} from './styles';


const Not_Authorized = () => {
    const { t } = useTranslation();
    return (
        <Container>
            <Image>
                <img src={back} alt="Imagem"></img>
            </Image>
            <WarningArea>
                <div className='Texts'>
                    <h1>{t("words.not_authorized")}</h1>
                    <a href='https://office.builderall.com/br/office/onboarding'>{t("words.back_office")}</a>
                    <a href="/">Login</a>
                </div>
            </WarningArea>
        </Container>
    )
}

export default Not_Authorized;